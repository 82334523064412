/* eslint-disable no-magic-numbers */
import Model, { attr, hasMany } from '@ember-data/model';

export default class Dataitem extends Model {
  @attr('string')
  address;

  @attr('number')
  score;

  @attr('number')
  lat;

  @attr('number')
  long;

  @attr('string')
  source;

  @hasMany('datasets', { async: true, inverse: 'dataitems' })
  datasets;

  get valid() {
    return this.score >= 95;
  }

  get invalid() {
    return this.score < 95;
  }

  get zipCode() {
    return this.address
      .split(',')
      [this.address.split(',').length - 1].replace(/ /g, '');
  }
}
