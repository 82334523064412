import { hash } from '@ember/helper';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class SliderComponent extends Component {
  @tracked
  selectedIndex = 0;

  @action
  next() {
    this.selectedIndex++;
    if (this.selectedIndex >= this.args.count) {
      this.selectedIndex = 0;
    }
  }

  @action
  previous() {
    this.selectedIndex--;
    if (this.selectedIndex < 0) {
      this.selectedIndex = this.args.count - 1;
    }
  }
  <template>
    {{yield this.selectedIndex (hash next=this.next previous=this.previous)}}
  </template>
}
