import * as Sentry from '@sentry/ember';
import isNetworkError from 'is-network-error';

import config from 'market-view-ui/config/environment';
import { NetworkError } from 'market-view-ui/services/network';

Sentry.init({
  dsn: 'https://eb451d65201264c11833c7abc8c40341@o135367.ingest.us.sentry.io/4507737370329088',
  integrations: [Sentry.replayIntegration()],
  environment: config.environment,
  release: config.sentry?.version,
  enabled: config.sentry.enabled,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [], // Object.values(URLS) -- set only if we set this up on the backend; requires certain headers to be in the response,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  beforeSend(event, hint) {
    const exception = hint?.originalException;
    // Filter out network errors caused by connectivity that go through our Network service
    if (exception instanceof NetworkError) {
      return null;
    }

    // Filter out other network errors that go through third-parties (e.g. mapbox)
    if (isNetworkError(exception)) {
      return null;
    }

    // Filter out TransitionAborted -- they just happen with the router and are expected
    if (exception?.message?.includes('TransitionAborted')) {
      return null;
    }

    // Filter out AbortErrors
    if (exception?.message?.includes('AbortError')) {
      return null;
    }

    return event;
  },
});
