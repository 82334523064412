import { modifier } from 'ember-modifier';

export default modifier(
  function vimeo(element, _, named) {
    let { Vimeo } = window;
    if (Vimeo) {
      let player = new Vimeo.Player(element);

      if (named.onPlay) {
        player.on('play', ({ percent }) => {
          named.onPlay(percent);
        });
      }

      if (named.onPause) {
        player.on('pause', ({ percent }) => {
          named.onPause(percent);
        });
      }

      return async () => {
        await player.destroy();
      };
    }
  },
  { eager: false },
);
