import { action } from '@ember/object';
import Service, { service } from '@ember/service';
import { isBlank } from '@ember/utils';

import { errorMessages } from 'market-view-ui/constants';

export default class OnboardingService extends Service {
  @service('session')
  session;

  @service
  log;

  @service
  metrics;

  @service('notifications')
  notifications;

  get currentUser() {
    return this.session?.currentUser;
  }

  get currentUserPreference() {
    return this.session?.currentUser?.userPreference;
  }

  get shouldShowWelcome() {
    return this.shouldShowTip('welcome');
  }

  get shouldShowFilters() {
    return this.shouldShowTip('filters');
  }

  get shouldShowSave() {
    return this.shouldShowTip('save');
  }

  get shouldShowLibrary() {
    return this.shouldShowTip('library');
  }

  get shouldShowShare() {
    return this.shouldShowTip('share');
  }

  shouldShowTip(key) {
    if (!this.currentUser.id) {
      return false;
    }
    let val = this.currentUserPreference?.showTips?.[key];
    return isBlank(val) || val;
  }

  @action
  async dismiss(key) {
    this.metrics.trackEvent({
      category: 'Onboarding',
      action: 'Dismissed Overlay',
      label: key,
    });

    let userPreference = await this.currentUserPreference;
    let tips = userPreference.showTips || {};
    tips[key] = false;
    userPreference.showTips = tips;

    try {
      await userPreference.save();
    } catch (e) {
      this.notifications.error(errorMessages.onboarding);
      this.log.error(e);
    }
  }
}
