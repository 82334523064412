import Service, { service } from '@ember/service';

const DEFAULT_TIMEOUT = 10000;

export default class NotificationsService extends Service {
  @service('flash-messages')
  notifications;

  success(params) {
    let { headline, message, icon, timeout = DEFAULT_TIMEOUT } = params;

    this.notifications.add({
      type: 'success',
      message,
      headline,
      icon,
      timeout,
    });
  }

  info(params) {
    let { headline, message, icon, timeout = DEFAULT_TIMEOUT } = params;

    this.notifications.add({
      type: 'info',
      message,
      headline,
      icon,
      timeout,
    });
  }

  error(params) {
    let {
      headline,
      message,
      icon = 'announcement',
      timeout = DEFAULT_TIMEOUT,
    } = params;

    this.notifications.add({
      type: 'error',
      message,
      headline,
      icon,
      timeout,
    });
  }
}
