import { service } from '@ember/service';
import JSONAPIAdapter from '@ember-data/adapter/json-api';

import { errorMessages } from 'market-view-ui/constants';

import { apiURL } from '../utils/api-url';

const Unauthorized = 401;

export default class Application extends JSONAPIAdapter {
  @service('session')
  session;

  @service('notifications')
  notifications;

  get host() {
    return apiURL();
  }

  get headers() {
    let token = this.session.token;

    return { Authorization: token };
  }

  namespace = 'api';

  handleResponse(status, headers, payload, requestData) {
    this.ensureResponseAuthorized(status, headers, payload, requestData);
    return super.handleResponse(...arguments);
  }

  async ensureResponseAuthorized(status) {
    if (status === Unauthorized && this.session.isAuthenticated) {
      await this.session.invalidate();
      this.notifications.error(errorMessages['401']);
    }
  }
}
