import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import OriginalSessionService from 'ember-simple-auth/services/session';

import config from 'market-view-ui/config/environment';

export default class SessionService extends OriginalSessionService {
  @service router;
  @service localStorage;
  @service cookies;

  @tracked validUntil;

  handleInvalidation() {
    if (config.authentication.fullReloadOnInvalidation) {
      super.handleInvalidation(...arguments);
    } else {
      return this.router.transitionTo('application');
    }
  }

  // This is NOT tested due to the way ember-simple-auth behaves in tests, to test:
  // 1. Log out
  // 2. Attempt to go directly to a URL in the app ala http://localhost:4200/data
  // 3. Verify redirected to /, click 'Login', login
  // 4. You should be returned to the URL you attempted to visit.
  handleAuthentication() {
    let attemptedUrl = this.localStorage.fetch('attemptedUrl');
    if (attemptedUrl) {
      this.localStorage.remove('attemptedUrl');
      return this.router.transitionTo(attemptedUrl);
    }
  }

  async authenticateWithSSOCookie() {
    const cookie = this.cookies.read(config.nais.ssoCookieName);
    if (cookie) {
      try {
        await this.authenticate('authenticator:nais', { cookie });
        return true;
      } catch {
        // do nothing -- failed to authenticate is fine
      }
    }
    return false;
  }
}
