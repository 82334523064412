import { LinkTo } from '@ember/routing';
<template>
  <article class="l-page">
    <section class="page__content">
      <LinkTo @route="application" class="page__back-link">
        &larr;Back to Market View
      </LinkTo>
      <h1 class="page__headline-primary">Our server is overloaded. Could we get
        an extension?</h1>
      <p class="t-body">Please check back later!</p>
    </section>
  </article>
</template>
