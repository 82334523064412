import { on } from '@ember/modifier';
import { LinkTo } from '@ember/routing';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import eq from 'ember-truth-helpers/helpers/eq';

import LandingTestimonial from 'market-view-ui/components/landing-testimonial';
import LoginButton from 'market-view-ui/components/login-button';
import Slider from 'market-view-ui/components/slider';
import VimeoPlayer from 'market-view-ui/components/vimeo-player';
import ssoUrl from 'market-view-ui/helpers/sso-url';
import trackEvent from 'market-view-ui/helpers/track-event';

<template>
  <div class="landing">
    <header class="l-header">
      <div class="l-header-wrap">
        <a href="/" aria-label="National Association of Independent Schools">
          <picture class="header__logo">
            <source
              media="(max-width: 799px)"
              width="103"
              height="10"
              srcset="/assets/svgs/mobile-only-logo.svg"
            />
            <source srcset="/assets/svgs/mv-logo.svg" width="245" height="51" />
            <img
              class="header__logo"
              srcset="/assets/images/mobile-only-logo.png 103w, /assets/svgs/mv-logo.svg 246w"
              sizes="(max-width: 799px) 103px, (min-width: 800px) 246px"
              src="/assets/svgs/mv-logo.svg"
              alt="Market View"
            />
          </picture>
        </a>
      </div>
    </header>
    <main class="landing__main">
      <section class="landing__welcome">
        <div class="landing__welcome__headlines">
          <h1 data-test-landing-headline>Welcome to Market View</h1>
          <h2>Use data to improve your recruitment strategy, find prospective
            families, and better understand your community.</h2>
          <div class="landing__actions">
            <LoginButton class="landing__btn" data-test-login />
            <a
              href="{{(ssoUrl)}}/sso/precreate"
              title="Create an account"
              class="landing__create"
              {{on
                "click"
                (trackEvent "Landing" "Link Clicked" "Sign up" isOutbound=true)
              }}
            >
              Sign up
            </a>
          </div>
        </div>
        <div class="landing__welcome__video">
          <VimeoPlayer
            @videoUrl="https://player.vimeo.com/video/548970201?title=0&byline=0&portrait=0"
            width="100%"
            height="100%"
          />
        </div>
      </section>
      <section class="landing__features">
        <div class="landing__features__icon">
          {{svgJar "lock" aria-hidden="true"}}
        </div>
        <div>
          <label>Safely Import School Data</label>
          <p>Personalize your Market View experience by securely importing your
            own student and school addresses. NAIS protects its members' privacy
            and keeps their data safe.</p>
        </div>
        <div class="landing__features__icon">
          {{svgJar "folder" aria-hidden="true"}}
        </div>
        <div>
          <label>Create Custom Presentations</label>
          <p>Market View also allows you to quickly create presentation-ready
            reports and to save your maps as images for your strategic
            documents.</p>
        </div>
        <div class="landing__features__icon">
          {{svgJar "doodle" aria-hidden="true"}}
        </div>
        <div>
          <label>Inform Admissions Strategy</label>
          <p>Our time-saving mapping tool comes complete with five-year
            projections of your community’s demographic data around historical
            enrollment in particular ZIP codes, including median income, traffic
            patterns, potential commute, and more.</p>
        </div>
        <div class="landing__features__icon">
          {{svgJar "group" aria-hidden="true"}}
        </div>
        <div>
          <label>Explore Your Community</label>
          <p>Explore demographic, race, and ethnicity data in your community to
            expand and tailor your admission strategy by region to reach more
            students of color.</p>
        </div>
      </section>
      <section class="landing__testimonials">
        {{! When adding a quote, increment the count and check that the min-height, both on mobile and desktop is sufficient for the longest quote (the height of the area should not "jump" when changing between quotes) }}
        <Slider @count={{8}} as |selectedIndex actions|>
          <button
            class="landing__testimonials__left"
            {{on "click" actions.previous}}
            name="Previous testimonial"
            title="Previous testimonial"
          >{{svgJar "chevron-left" width="32px"}}</button>
          <LandingTestimonial
            @quote="Being able to help folks see not only the numbers but where they show up geographically and in relation to our school, is extremely, extremely powerful."
            @author="Brian Jarvis"
            @title="Assistant Admissions Director"
            @school="Norfolk Academy"
            @isSelected={{eq selectedIndex 0}}
          />
          <LandingTestimonial
            @quote="The types of demographics provided in Market View help drive our enrollment and recruitment strategies; we are grateful to NAIS for developing this comprehensive tool!"
            @author="Kathryn Purcell"
            @title="Associate Head of School"
            @school="Laurel School"
            @isSelected={{eq selectedIndex 1}}
          />
          <LandingTestimonial
            @quote="We all need data for decision making. These tools that NAIS gives us allow us to do that, and do it over time as well."
            @author="Jennifer Vrana"
            @title="Director of Finance and Operations"
            @school="The Independence School"
            @isSelected={{eq selectedIndex 2}}
          />
          <LandingTestimonial
            @quote="With fairly minimal training that admissions people can be shown how to extract useful information [from MV Trend Report] with a minimum amount of investment in that process."
            @author="Geordie Mitchell"
            @title="Assistant Head of School for Enrollment & Outreach"
            @school="La Jolla Country Day School"
            @isSelected={{eq selectedIndex 3}}
          />
          <LandingTestimonial
            @quote="What I was able to pull as far as the visuals was fantastic […] because it is so straight forward […] I can pretty much deliver that right to [the board] with the NAIS logo on it. And that is so incredibly valuable because it’s a third party tool […] and brand that is very trustworthy. [It allows the board] to start the discussion based on real numbers."
            @author="Danielle Beale"
            @title="Assistant to the Head of School"
            @school="Nantucket New School"
            @isSelected={{eq selectedIndex 4}}
          />
          <LandingTestimonial
            @quote="One of the best parts of map view is the research tab, because that's, you know, if nothing else what I'm saying to my colleagues who are in small shops, or don't have a data background hit the research tab, the work's been done for you and 80% of what you need is right there. Good to be able to sound informed, talk to your head, talk to your CFO, talk to your board. That research tab is gold."
            @author="Geordie Mitchell"
            @title="Assistant Head of School for Enrollment & Outreach"
            @school="La Jolla Country Day School"
            @isSelected={{eq selectedIndex 5}}
          />
          <LandingTestimonial
            @quote="Using Market View, we saved $15,000 on a contract [...] to do a demographic search of where our families were coming from. And [...] I can see that [data] Saturday on the back deck with a big bottle of water sitting in the shade and I can dive in and save the school $15,000."
            @author="Ashley Harper"
            @title="Head of School"
            @school="Wakefield School"
            @isSelected={{eq selectedIndex 6}}
          />
          <LandingTestimonial
            @quote="I was familiar with Market View before coming [into headship] so I knew it was going to be a useful tool, and it’s part of the reason why I lobbied for us becoming members this year, because I felt as we wanted to grow and expand, we really needed to know our market […] MV put all of that information together on one screen overlaid just makes it a much more compelling to decision makers."
            @author="Adriana Murphy"
            @title="Head of School"
            @school="St. Elizabeth’s School"
            @isSelected={{eq selectedIndex 7}}
          />
          <button
            class="landing__testimonials__right"
            {{on "click" actions.next}}
            name="Next testimonial"
            title="Next testimonial"
          >{{svgJar "chevron-right-original"}}</button>
        </Slider>
      </section>
    </main>
    <footer class="landing__footer">
      {{svgJar "nais-landing" class="landing__footer__logo"}}
      <div class="landing__footer__membership">
        <label>Membership</label>
        <p>NAIS members have full access to Market View, and receive special
          discounts for add-on products.</p>
      </div>
      <address class="landing__footer__contact">
        <label>Contact</label>
        <p><strong>National Association of Independent Schools</strong></p>
        <p><a href="tel:+12029739700">(202) 973-9700</a></p>
      </address>
      <a
        href="https://www.nais.org/membership/"
        class="btn btn--primary landing__btn landing__footer__join"
        {{on
          "click"
          (trackEvent
            "Landing" "Link Clicked" "Become a Member Today" isOutbound=true
          )
        }}
      >
        Become a Member Today
      </a>
      <a
        href="https://docs.marketview.nais.org/nais"
        target="_blank"
        rel="noopener noreferrer"
        class="btn btn--primary landing__btn landing__footer__help"
        {{on
          "click"
          (trackEvent "User" "Help Viewed" "from landing page" isOutbound=true)
        }}
      >
        Get Help
      </a>
      <div class="landing__footer__terms-copyright">
        <LinkTo @route="terms" class="landing__footer__terms">
          Terms & Conditions
        </LinkTo>
        <small class="landing__footer__copyright">© NATIONAL ASSOCIATION OF
          INDEPENDENT SCHOOLS</small>
      </div>
    </footer>
  </div>
</template>
