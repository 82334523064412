import setupDeprecationWorkflow from 'ember-cli-deprecation-workflow';

/**
 * Docs: https://github.com/ember-cli/ember-cli-deprecation-workflow
 */
setupDeprecationWorkflow({
  /**
    false by default, but if a developer / team wants to be more aggressive about being proactive with
    handling their deprecations, this should be set to "true"
  */
  throwOnUnhandled: false,
  workflow: [
    /* Ember */
    { handler: 'throw', matchId: 'template-action' },
    { handler: 'silence', matchId: 'importing-inject-from-ember-service' },

    /* Ember Data */
    {
      handler: 'throw',
      matchId: 'ember-data:deprecate-non-strict-relationships',
    },
    { handler: 'throw', matchId: 'ember-data:deprecate-early-static' },
    { handler: 'throw', matchId: 'ember-data:deprecate-array-like' },
    { handler: 'silence', matchId: 'ember-data:deprecate-promise-proxies' },
    {
      handler: 'silence',
      matchId: 'ember-data:deprecate-promise-many-array-behaviors',
    },
    { handler: 'throw', matchId: 'ember-data:no-a-with-array-like' },
  ],
});
