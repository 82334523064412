import BaseAdapter from 'ember-metrics/metrics-adapters/base';
export default class CustomIntercom extends BaseAdapter {
  toStringExtension() {
    return 'CustomIntercom';
  }

  install() {
    /* Installed via script tag */
  }

  trackEvent(options = {}) {
    const { action, category, label } = options;

    if (category !== 'Onboarding' || category !== 'Comparison Feedback') {
      return; // Only send 'Onboarding' events to Intercom
    }

    delete options.action;
    delete options.category;
    delete options.label;

    window.Intercom(
      'trackEvent',
      [category, action, label].join(' ').trimStart(),
      options,
    );
  }

  trackPage() {
    /* Do not send page events to intercom */
  }

  uninstall() {}
}
