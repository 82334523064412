import { LinkTo } from '@ember/routing';
<template>
  <main class="l-page">
    <article>
      <section class="page__content">
        <LinkTo @route="application" class="page__back-link">
          &larr;Back to Market View
        </LinkTo>
        <header class="page__header">
          <h1 class="page__headline-primary">
            Market View Data Guide
          </h1>
          <h2 class="t-subhead-primary page__subhead">
            Market View synthesizes several pieces of data to create the map you
            see upon logging in. These data include the following:
          </h2>
        </header>
        <h3 class="t-headline-secondary page__headline-secondary">Table of
          Contents</h3>
        <nav class="page__toc">
          <ol>
            <li>
              <a href="#PopulationByAge">1. Population by Age Band: EASI
                Demographics</a>
            </li>
            <li>
              <a href="#FamilyIncome">2. Family Income, EASI Demographics</a>
            </li>
            <li>
              <a href="#PopulationBySex">3. Population by Sex by Race/Ethnicity,
                EASI Demographics</a>
            </li>
            <li>
              <a href="#DriveTime">4. Drive-Time Area, Mapbox Navigation Service</a>
            </li>
            <li>
              <a href="#Projections">5. Five-year Data Projections, EASI
                Demographics</a>
            </li>
            <li>
              <a href="#PublicAndPrivateSchool">6. Public and Private School
                Data, National Center for Education Statistics (NCES)</a>
            </li>
            <li>
              <a href="#Affordability">7. Affordability Data</a>
            </li>
          </ol>
        </nav>
        <h3
          id="PopulationByAge"
          class="t-headline-secondary page__headline-secondary headline-with-anchor"
        >
          Population by Age Band: EASI Demographics
        </h3>
        <p class="t-body page__subhead-secondary">
          What is it?
        </p>
        <p class="t-body">
          EASI provides population estimates by age band (0-4,5-9, etc.) for the
          current year. These estimates are created using the 2020 census
          numbers, American Community Survey Data, and Public Use Microdata
          Survey. To calculate projections, EASI uses the estimates above as a
          starting point and applies population component change estimates
          (births, deaths, migration, etc.) to move that number forward.
          <a href="https://www.easidemographics.com/about/easiMethods.asp">
            Read the full methodology here.
          </a>
        </p>
        <p class="t-body page__subhead-secondary">
          How do we use it?
        </p>
        <p class="t-body">
          EASI’s age band estimates are used to provide an approximation of the
          school-age population of a single-year age across ZIP codes. These
          estimates are then combined with mapping technology supplied by Mapbox
          (described below) to generate the customizable map that you see within
          Market View.
        </p>
        <hr />
        <h3
          id="FamilyIncome"
          class="t-headline-secondary page__headline-secondary headline-with-anchor"
        >
          Family Income, EASI Demographics
        </h3>
        <p class="t-body page__subhead-secondary">
          What is it?
        </p>
        <p class="t-body">
          EASI’s income model builds on a base estimate created using 2020
          census data and estimates from the American Community Survey. EASI
          creates estimates from here by using consumer income survey data
          provided by the census (P60 Publication Series) and other sources of
          income data. Differences between the estimates provided from these
          data and the ACS values are analyzed to provide a final estimate.
          Income estimates use the 2020 census household definition of income as
          a benchmark.
          <a href="https://www.easidemographics.com/about/easiMethods.asp">
            Read the full methodology here.
          </a>
        </p>
        <p class="t-body page__subhead-secondary">
          How do we use it?
        </p>
        <p class="t-body">
          EASI’s median income estimates provide an economic snapshot of the
          various areas in your local community. EASI’s estimates of median
          family income can be used to get a sense of the wealth distribution of
          your area, or to see how affordable your school is for a typical
          family in your community.
        </p>
        <hr />
        <h3
          id="PopulationBySex"
          class="t-headline-secondary page__headline-secondary headline-with-anchor"
        >
          Population by Sex by Race/Ethnicity, EASI Demographics
        </h3>
        <p class="t-body page__subhead-secondary">
          What is it?
        </p>
        <p class="t-body">
          EASI also provides population estimates by sex and race/ethnicity for
          age bands in the current year. EASI uses annual estimates released by
          the census as the basis for their population by sex by race/ethnicity
          data. To calculate projections, EASI uses the census estimate as a
          starting point and applies population component change estimates
          (births, deaths, migration, etc.) to move that number forward.
          <a href="https://www.easidemographics.com/about/easiMethods.asp">
            Read the full methodology here.
          </a>
        </p>
        <p class="t-body page__subhead-secondary">
          How do we use it?
        </p>
        <p class="t-body">
          EASI’s estimates for population by race/ethnicity provide schools with
          a fuller picture of the diversity of their local student population.
          These estimates are useful for schools seeking to understand how the
          racial and ethnic diversity of their student body compares to the
          local population. In addition, EASI’s estimates for population by sex
          provide more flexibility for single sex schools and other schools
          seeking to understand local differences in male and female
          populations. Note that Market View does not currently contain data on
          gender, as the underlying sources of data (EASI and the Census Bureau)
          do not currently collect this data.
        </p>
        <hr />
        <h3
          id="DriveTime"
          class="t-headline-secondary page__headline-secondary headline-with-anchor"
        >
          Drive-Time Area, Mapbox Navigation Service
        </h3>
        <p class="t-body page__subhead-secondary">
          What is it?
        </p>
        <p class="t-body">
          Mapbox’s traffic tilesets provide drivetime information, or the time
          it takes to get from point A to point B, to their maps. Mapbox bases
          its drivetime estimates on travel data that is transmitted from users
          of apps embedded with Mapbox technology, such as Airbnb, Instacart,
          and Snapchat. This data, which consists of over 220 million miles of
          travel per day, is used to predict and extrapolate real-time traffic,
          helping to further improve the accuracy of drivetimes.
          <a href="https://www.mapbox.com/telemetry/">
            Read the full methodology here.
          </a>
        </p>
        <p class="t-body page__subhead-secondary">
          How do we use it?
        </p>
        <p class="t-body">
          We heard from members that time, not distance, is the most important
          metric in measuring a commute. As opposed to simply measuring
          distance, drivetimes provide a more comprehensive understanding of how
          conveniently located your school is for families in the area. In
          addition, Mapbox’s traffic control features provide a realistic
          understanding of the time it will take families to reach your school
          during typical commuting hours.
        </p>
        <hr />
        <h3
          id="Projections"
          class="t-headline-secondary page__headline-secondary headline-with-anchor"
        >
          Five-year Data Projections, EASI Demographics
        </h3>
        <p class="t-body page__subhead-secondary">
          What is it?
        </p>
        <p class="t-body">
          EASI demographics provides five year projections for the population
          and income variables described above. These projections use EASI’s
          current year estimates as a starting point, and apply growth models to
          obtain a five year projection. The models incorporate factors such as
          births, deaths, and migration to obtain population projections, and
          build on data from the American Community Survey and Census Consumer
          Income Survey to obtain income projections.
          <a href="https://www.easidemographics.com/about/easiMethods.asp">
            Read the full methodology here.
          </a>
        </p>
        <p class="t-body page__subhead-secondary">
          How do we use it?
        </p>
        <p class="t-body">
          EASI’s projections are a great way to get a sense of how your local
          area will be changing over the next several years. While projections
          are not often 100 percent accurate, they are a great starting point to
          see trends and begin conversations around how the school-age
          population, demographics, and income profile of your area are
          changing.
        </p>
        <hr />
        <h3
          id="PublicAndPrivateSchool"
          class="t-headline-secondary page__headline-secondary headline-with-anchor"
        >
          Public and Private School Data, National Center for Education
          Statistics (NCES)
        </h3>
        <p class="t-body page__subhead-secondary">
          What is it?
        </p>
        <p class="t-body">
          The National Center for Education Statistics (NCES) provides data on
          public and private schools, which is gathered through its universe
          surveys each year. The surveys provide data on many schools across the
          United States, including basic descriptive information such as
          location, web address, and grade levels served.
        </p>
        <p class="t-body page__subhead-secondary">
          How do we use it?
        </p>
        <p class="t-body">
          NCES’s school data is uploaded into Market View’s map, so you can see
          other schools in your area. Each school is represented by a black
          circle on your map. Clicking the circle will provide the school’s
          name, type (Public/Private), website (where available), and grade
          levels served.
        </p>
        <hr />
        <h3
          id="Affordability"
          class="t-headline-secondary page__headline-secondary headline-with-anchor"
        >
          Affordability Data
        </h3>
        <p class="t-body page__subhead-secondary">
          What is it?
        </p>
        <p class="t-body">
          NAIS uses data provided by Community Brands’ School and Student
          Services (SSS) financial aid service which analyzes applications for
          families across the country. Using the family income and parent
          contribution data from submitted applications, we calculate the median
          percentage of income that parents are asked to contribute to school
          costs by income bands. The contribution is subtracted from the tuition
          entered by the user to determine the estimated financial gap a family
          is likely to have to cover.
        </p>
        <p class="t-body page__subhead-secondary">
          How do we use it?
        </p>
        <p class="t-body">
          By estimating the financial gap between what a family at the median
          income in a ZIP code is able to pay and the tuition your school, you
          will have a better picture of how affordable your tuition is in your
          market.
        </p>
      </section>
    </article>
  </main>
</template>
