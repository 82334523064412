import { MAX_INCOME } from '../constants';

export const ContributionRates = [
  {
    low: 0,
    high: 4999,
    rate: 0.0,
  },
  {
    low: 5000,
    high: 9999,
    rate: 0.0,
  },
  {
    low: 10000,
    high: 14999,
    rate: 0.0,
  },
  {
    low: 15000,
    high: 19999,
    rate: 0.0,
  },
  {
    low: 20000,
    high: 24999,
    rate: 0.0,
  },
  {
    low: 25000,
    high: 29999,
    rate: 0.0,
  },
  {
    low: 30000,
    high: 34999,
    rate: 0.0,
  },
  {
    low: 35000,
    high: 39999,
    rate: 0.0,
  },
  {
    low: 40000,
    high: 44999,
    rate: 0.0,
  },
  {
    low: 45000,
    high: 49999,
    rate: 0.0,
  },
  {
    low: 50000,
    high: 54999,
    rate: 0.0,
  },
  {
    low: 55000,
    high: 59999,
    rate: 0.0,
  },
  {
    low: 60000,
    high: 64999,
    rate: 0.0109,
  },
  {
    low: 65000,
    high: 69999,
    rate: 0.0202,
  },
  {
    low: 70000,
    high: 74999,
    rate: 0.0324,
  },
  {
    low: 75000,
    high: 79999,
    rate: 0.0424,
  },
  {
    low: 80000,
    high: 84999,
    rate: 0.0486,
  },
  {
    low: 85000,
    high: 89999,
    rate: 0.0566,
  },
  {
    low: 90000,
    high: 94999,
    rate: 0.0684,
  },
  {
    low: 95000,
    high: 99999,
    rate: 0.0753,
  },
  {
    low: 100000,
    high: 104999,
    rate: 0.0846,
  },
  {
    low: 105000,
    high: 109999,
    rate: 0.0964,
  },
  {
    low: 110000,
    high: 114999,
    rate: 0.1039,
  },
  {
    low: 115000,
    high: 119999,
    rate: 0.1139,
  },
  {
    low: 120000,
    high: 124999,
    rate: 0.12,
  },
  {
    low: 125000,
    high: 129999,
    rate: 0.1286,
  },
  {
    low: 130000,
    high: 134999,
    rate: 0.134,
  },
  {
    low: 135000,
    high: 139999,
    rate: 0.1433,
  },
  {
    low: 140000,
    high: 144999,
    rate: 0.1458,
  },
  {
    low: 145000,
    high: 149999,
    rate: 0.1518,
  },
  {
    low: 150000,
    high: 154999,
    rate: 0.1589,
  },
  {
    low: 155000,
    high: 159999,
    rate: 0.1632,
  },
  {
    low: 160000,
    high: 164999,
    rate: 0.1708,
  },
  {
    low: 165000,
    high: 169999,
    rate: 0.1716,
  },
  {
    low: 170000,
    high: 174999,
    rate: 0.1782,
  },
  {
    low: 175000,
    high: 179999,
    rate: 0.1819,
  },
  {
    low: 180000,
    high: 184999,
    rate: 0.182,
  },
  {
    low: 185000,
    high: 189999,
    rate: 0.1885,
  },
  {
    low: 190000,
    high: 194999,
    rate: 0.1886,
  },
  {
    low: 195000,
    high: 199999,
    rate: 0.1941,
  },
  {
    low: 200000,
    high: 204999,
    rate: 0.2015,
  },
  {
    low: 205000,
    high: 209999,
    rate: 0.202,
  },
  {
    low: 210000,
    high: 214999,
    rate: 0.2074,
  },
  {
    low: 215000,
    high: 219999,
    rate: 0.205,
  },
  {
    low: 220000,
    high: 224999,
    rate: 0.2114,
  },
  {
    low: 225000,
    high: 229999,
    rate: 0.2149,
  },
  {
    low: 230000,
    high: 234999,
    rate: 0.2164,
  },
  {
    low: 235000,
    high: 239999,
    rate: 0.2175,
  },
  {
    low: 240000,
    high: 244999,
    rate: 0.2212,
  },
  {
    low: 245000,
    high: 249999,
    rate: 0.2191,
  },
  {
    low: 250000,
    high: 254999,
    rate: 0.223,
  },
  {
    low: 255000,
    high: 259999,
    rate: 0.2332,
  },
  {
    low: 260000,
    high: 264999,
    rate: 0.2323,
  },
  {
    low: 265000,
    high: 269999,
    rate: 0.23,
  },
  {
    low: 270000,
    high: 274999,
    rate: 0.2342,
  },
  {
    low: 275000,
    high: 279999,
    rate: 0.2323,
  },
  {
    low: 280000,
    high: 284999,
    rate: 0.2379,
  },
  {
    low: 285000,
    high: 289999,
    rate: 0.2413,
  },
  {
    low: 290000,
    high: 294999,
    rate: 0.2398,
  },
  {
    low: 295000,
    high: 299999,
    rate: 0.237,
  },
  {
    low: 300000,
    high: 499999,
    rate: 0.2621,
  },
  {
    low: 500000,
    high: 999999,
    rate: 0.2793,
  },
  {
    low: 1000000,
    high: MAX_INCOME,
    rate: 0.2377,
  },
];

export function contributionAtIncome(income) {
  let rate = ContributionRates.find((r) => income <= r.high && income >= r.low);
  return rate.rate * income;
}
