import Helper from '@ember/component/helper';
import { service } from '@ember/service';

export default class TrackEvent extends Helper {
  @service
  metrics;

  compute([category, action, label, value], { isOutbound = false }) {
    let options = {
      category,
      action,
      label,
      value,
    };
    if (isOutbound) {
      options['transport'] = 'beacon';
    }

    return () => {
      if (this.metrics) {
        this.metrics.trackEvent(options);
      }
    };
  }
}
