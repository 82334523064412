import Service, { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { dropTask, restartableTask, task } from 'ember-concurrency';
import { tracked } from 'tracked-built-ins';

import {
  CURRENT_DATA_YEAR,
  FORECAST_DATA_YEAR,
  PAST_DATA_YEAR,
  MEDIAN_NATIONAL_TUITION,
} from '../constants';
import transformKeys from '../utils/transform-keys';

export default class TrendsService extends Service {
  @service network;
  @service store;
  @service location;
  @service router;
  @service log;

  // eslint-disable-next-line no-magic-numbers
  @tracked driveTime = 30;
  @tracked tuition = MEDIAN_NATIONAL_TUITION;
  @tracked limit = 'all';
  @tracked sort = 'proximity';

  @tracked data = {};

  @tracked numAdmissionsListItems = null;

  zips;

  updateDriveTime = restartableTask(async (value) => {
    this.driveTime = value;
    await this.loadData.perform();
  });

  updateLimit = restartableTask(async (value) => {
    this.limit = value;
    await this.loadData.perform();
  });

  updateSort = restartableTask(async (value) => {
    this.sort = value;
    await this.loadData.perform();
  });

  loadData = restartableTask(async () => {
    await this.loadBaseData.perform();
    await this.loadRouteData.perform();
  });

  loadRouteData = restartableTask(
    waitFor(async (name) => {
      let routeName = name || this.router.currentRouteName;
      let data;
      if (routeName.match(/authenticated\.trends\.index\./)) {
        data = await this.loadOverviewData.perform();
      }
      if (routeName.match(/authenticated\.trends\.affordability\./)) {
        data = await this.loadAffordabilityData.perform();
      }
      if (routeName.match(/authenticated\.trends\.student-body\./)) {
        data = await this.loadStudentBodyData.perform();
      }
      if (routeName.match(/authenticated\.trends\.diversity\./)) {
        data = await this.loadDiversityData.perform();
      }
      if (routeName.match(/authenticated\.trends\.enrollment\./)) {
        data = await this.loadEnrollmentData.perform();
      }
      data.zips = this.zips;
      data.fullZipList = this.fullZipList;
      this.data = data;
    }),
  );

  loadBaseData = restartableTask(async () => {
    await this.fetchZips.perform();
  });

  loadOverviewData = restartableTask(async () => {
    let data = {};
    data.forecastZips = await this.forecastZips.perform();
    data.historicalZips = await this.historicalZips.perform();
    data.isochrone = await this.fetchIsochrone.perform();
    data.zipGeometries = await this.zipGeometries.perform();
    return data;
  });

  loadAffordabilityData = restartableTask(async () => {
    let data = {};
    data.medianHouseholdIncomeData =
      await this.medianHouseholdIncomeData.perform();
    data.nationalMedianHouseholdIncomeData =
      await this.nationalMedianHouseholdIncomeData.perform();
    data.changeAverageHouseholdIncomeByRaceData =
      await this.changeAverageHouseholdIncomeByRaceData.perform();
    data.highIncomeFamilies = await this.highIncomeFamilies.perform();
    data.householdIncomeDistributionData =
      await this.householdIncomeDistributionData.perform();
    data.highestEarningZips = await this.highestEarningZips.perform();
    return data;
  });

  loadStudentBodyData = restartableTask(async () => {
    let data = {};
    data.populationEnrolledInPrivateSchool =
      await this.populationEnrolledInPrivateSchool.perform();
    data.schoolAgePopulationByAge =
      await this.schoolAgePopulationByAge.perform();
    data.changeInSchoolAgePopulation =
      await this.changeInSchoolAgePopulation.perform();
    return data;
  });

  loadDiversityData = restartableTask(async () => {
    let data = {};
    data.schoolAgePopulationByRaceData =
      await this.schoolAgePopulationByRaceData.perform();
    data.changePopulationByRaceEthnicityByZip =
      await this.changePopulationByRaceEthnicityByZip.perform();
    data.schoolAgePopulationByEthnicity =
      await this.schoolAgePopulationByEthnicity.perform();
    data.changeInSchoolAgePopulationByRaceEthnicity =
      await this.changeInSchoolAgePopulationByRaceEthnicity.perform();
    return data;
  });

  loadEnrollmentData = restartableTask(async () => {
    let data = {};
    data.schoolEnrollmentTrendsByZip =
      await this.schoolEnrollmentTrendsByZip.perform();
    data.forecastschoolEnrollmentTrendsByZip =
      await this.schoolEnrollmentTrendsByZip.perform(
        CURRENT_DATA_YEAR,
        FORECAST_DATA_YEAR,
      );
    data.schoolEnrollmentTrends = await this.schoolEnrollmentTrends.perform();
    data.schoolEnrollmentTrendsApplications =
      await this.schoolEnrollmentTrendsApplications.perform();
    data.schoolEnrollmentTrendsEnrollment =
      await this.schoolEnrollmentTrendsEnrollment.perform();
    data.schoolEnrollmentTrendsDiversity =
      await this.schoolEnrollmentTrendsDiversity.perform();
    data.schoolEnrollmentTrendsFinancialAid =
      await this.schoolEnrollmentTrendsFinancialAid.perform();
    return data;
  });

  zipGeometries = restartableTask(async () => {
    let geos = await this.network.request(
      `/api/zctas?filter[lat]=${this.location.lat}&filter[long]=${this.location.long}&filter[drive-time]=${this.driveTime}`,
    );
    return geos.data;
  });

  fetchIsochrone = restartableTask(async () => {
    let isochrone = await this.network.request(
      `/api/isochrones?filter[lat]=${this.location.lat}&filter[long]=${this.location.long}&filter[drive-time]=${this.driveTime}`,
    );
    if (isochrone && isochrone.data && isochrone.data.attributes) {
      return isochrone.data.attributes.geometry;
    }
  });

  fetchZips = restartableTask(async () => {
    if (!this.location.lat || !this.location.long) {
      this.log.error('Missing lat or long when fetching zips from trends');
    }
    let query = {
      lat: this.location.lat,
      long: this.location.long,
      'drive-time': this.driveTime,
      sort: this.sort,
      year: CURRENT_DATA_YEAR,
    };
    if (this.limit !== 'all') {
      query['limit'] = this.limit;
    }

    let zipData = await this.network.request(
      `/api/demographics/zip-codes?${filterString(query)}`,
    );
    this.zips = transform(zipData.data);
    this.fullZipList = transformMeta(zipData.meta?.['zip-codes']);
  });

  forecastZips = restartableTask(async () => {
    return await this.fetchZipsForYear.perform(FORECAST_DATA_YEAR);
  });

  historicalZips = restartableTask(async () => {
    return await this.fetchZipsForYear.perform(PAST_DATA_YEAR);
  });

  fetchZipsForYear = task(async (year) => {
    let query = {
      lat: this.location.lat,
      long: this.location.long,
      'drive-time': this.driveTime,
      sort: this.sort,
      year,
    };
    if (this.limit !== 'all') {
      query['limit'] = this.limit;
    }
    let zipData = await this.network.request(
      `/api/demographics/zip-codes?${filterString(query)}`,
    );
    return transform(zipData.data);
  });

  medianHouseholdIncomeData = dropTask(async () => {
    let medianHouseholdIncomeData = await this.network.request(
      `/api/chart-data/median-household-income?filter[zips]=${this.zipParam}`,
    );
    return medianHouseholdIncomeData.data;
  });

  nationalMedianHouseholdIncomeData = dropTask(async () => {
    let medianHouseholdIncomeData = await this.network.request(
      `/api/chart-data/median-household-income`,
    );
    return medianHouseholdIncomeData.data;
  });

  changeAverageHouseholdIncomeByRaceData = dropTask(async () => {
    let past = await this.network.request(
      `/api/chart-data/change-average-household-income-by-race?filter[zips]=${this.zipParam}&filter[begin]=${PAST_DATA_YEAR}&filter[end]=${CURRENT_DATA_YEAR}`,
    );
    let projection = await this.network.request(
      `/api/chart-data/change-average-household-income-by-race?filter[zips]=${this.zipParam}&filter[begin]=${CURRENT_DATA_YEAR}&filter[end]=${FORECAST_DATA_YEAR}`,
    );
    return { past: past.data, projection: projection.data };
  });

  schoolAgePopulationByRaceData = dropTask(async () => {
    let schoolAgePopulationByRaceData = await this.network.request(
      `/api/chart-data/school-age-population-by-race?filter[zips]=${this.zipParam}&filter[year]=${CURRENT_DATA_YEAR}`,
    );
    return schoolAgePopulationByRaceData.data;
  });

  householdIncomeDistributionData = dropTask(async () => {
    let householdIncomeDistributionData = await this.network.request(
      `/api/chart-data/household-income-distribution?filter[zips]=${this.zipParam}&filter[year]=${CURRENT_DATA_YEAR}`,
    );
    return householdIncomeDistributionData.data;
  });

  changePopulationByRaceEthnicityByZip = restartableTask(
    async (begin = PAST_DATA_YEAR, end = CURRENT_DATA_YEAR) => {
      let highestEarningZips = await this.highestEarningZips.perform();
      let topDensityZips = await this.topDensityZips.perform();
      let topProximityZips = await this.topProximityZips.perform();
      let zipParam = asZipParam([
        ...highestEarningZips,
        ...topDensityZips,
        ...topProximityZips,
      ]);
      let data = await this.network.request(
        `/api/chart-data/change-population-by-race-ethnicity-by-zip?filter[zips]=${zipParam}&filter[begin]=${begin}&filter[end]=${end}`,
      );
      return {
        changeData: data.data,
        highestEarningZips,
        topDensityZips,
        topProximityZips,
        begin,
        end,
      };
    },
  );

  updateChangePopulationByRaceEthnicityByZip = restartableTask(
    async (begin, end) => {
      let result = await this.changePopulationByRaceEthnicityByZip.perform(
        begin,
        end,
      );
      this.data = Object.assign({}, this.data, {
        changePopulationByRaceEthnicityByZip: result,
      });
    },
  );

  updateYearsForschoolEnrollmentTrendsByZip = restartableTask(async (begin) => {
    let result = await this.schoolEnrollmentTrendsByZip.perform(begin);
    this.data = Object.assign({}, this.data, {
      schoolEnrollmentTrendsByZip: result,
    });
  });

  highIncomeFamilies = dropTask(async () => {
    let highIncomeFamilies = await this.network.request(
      `/api/chart-data/high-income-families?filter[zips]=${this.zipParam}`,
    );
    return highIncomeFamilies.data;
  });

  populationEnrolledInPrivateSchool = dropTask(async () => {
    let population = await this.network.request(
      `/api/chart-data/enrolled-private-school?filter[zips]=${this.zipParam}`,
    );
    return population.data;
  });

  schoolAgePopulationByAge = dropTask(async () => {
    let population = await this.network.request(
      `/api/chart-data/school-age-population-by-age?filter[zips]=${this.zipParam}&filter[new-age-ranges]=true`,
    );
    return population.data;
  });

  schoolAgePopulationByEthnicity = dropTask(async () => {
    let population = await this.network.request(
      `/api/chart-data/school-age-population-by-ethnicity?filter[zips]=${this.zipParam}&filter[year]=${CURRENT_DATA_YEAR}`,
    );
    return population.data;
  });

  schoolEnrollmentTrends = dropTask(
    async (
      begin = new Date().getFullYear() - 2,
      end = new Date().getFullYear(),
    ) => {
      let trends = await this.network.request(
        `/api/school-trends/trends?filter[zips]=${this.zipParam}&filter[begin]=${begin}&filter[end]=${end}`,
      );
      return transformKeys(trends);
    },
  );

  schoolEnrollmentTrendsApplications = dropTask(
    async (
      begin = new Date().getFullYear() - 2,
      end = new Date().getFullYear(),
    ) => {
      let trends = await this.network.request(
        `/api/school-trends/trends/applications?filter[zips]=${this.zipParam}&filter[begin]=${begin}&filter[end]=${end}`,
      );
      return transformKeys(trends, { topLevelOnly: true });
    },
  );

  schoolEnrollmentTrendsEnrollment = dropTask(
    async (
      begin = new Date().getFullYear() - 2,
      end = new Date().getFullYear(),
    ) => {
      let trends = await this.network.request(
        `/api/school-trends/trends/enrollments?filter[zips]=${this.zipParam}&filter[begin]=${begin}&filter[end]=${end}`,
      );
      return transformKeys(trends, { topLevelOnly: true });
    },
  );

  schoolEnrollmentTrendsDiversity = dropTask(
    async (
      // eslint-disable-next-line no-magic-numbers
      begin = new Date().getFullYear() - 3,
      end = new Date().getFullYear() - 1,
    ) => {
      let trends = await this.network.request(
        `/api/school-trends/trends/diversity?filter[zips]=${this.zipParam}&filter[begin]=${begin}&filter[end]=${end}`,
      );
      return transformKeys(trends, { topLevelOnly: true });
    },
  );

  schoolEnrollmentTrendsFinancialAid = dropTask(
    async (
      // eslint-disable-next-line no-magic-numbers
      begin = new Date().getFullYear() - 3,
      end = new Date().getFullYear() - 1,
    ) => {
      let trends = await this.network.request(
        `/api/school-trends/trends/financial-aid?filter[begin]=${begin}&filter[end]=${end}`,
      );
      return transformKeys(trends, { topLevelOnly: true });
    },
  );

  schoolEnrollmentTrendsByZip = restartableTask(
    async (
      begin = new Date().getFullYear() - 2,
      end = new Date().getFullYear(),
    ) => {
      let trends = await this.network.request(
        `/api/school-trends?filter[zips]=${this.zipParam}&filter[begin]=${begin}&filter[end]=${end}`,
      );
      return transformKeys(trends);
    },
  );

  highestEarningZips = restartableTask(async () => {
    let zipData = await this.network.request(
      `/api/demographics/zip-codes?filter[lat]=${this.location.lat}&filter[long]=${this.location.long}&filter[drive-time]=${this.driveTime}&filter[year]=${CURRENT_DATA_YEAR}&filter[sort]=income&filter[limit]=5`,
    );
    return transform(zipData.data);
  });

  topProximityZips = restartableTask(async () => {
    let zipData = await this.network.request(
      `/api/demographics/zip-codes?filter[lat]=${this.location.lat}&filter[long]=${this.location.long}&filter[drive-time]=${this.driveTime}&filter[year]=${CURRENT_DATA_YEAR}&filter[sort]=proximity&filter[limit]=5`,
    );
    return transform(zipData.data);
  });

  topDensityZips = restartableTask(async () => {
    let zipData = await this.network.request(
      `/api/demographics/zip-codes?filter[lat]=${this.location.lat}&filter[long]=${this.location.long}&filter[drive-time]=${this.driveTime}&filter[year]=${CURRENT_DATA_YEAR}&filter[sort]=density&filter[limit]=5`,
    );
    return transform(zipData.data);
  });

  changeInSchoolAgePopulation = dropTask(async () => {
    let change = await this.network.request(
      `/api/chart-data/change-school-age-population?filter[zips]=${this.zipParam}&filter[begin]=${PAST_DATA_YEAR}&filter[end]=${CURRENT_DATA_YEAR}&filter[new-age-ranges]=true`,
    );
    let futureChange = await this.network.request(
      `/api/chart-data/change-school-age-population?filter[zips]=${this.zipParam}&filter[begin]=${CURRENT_DATA_YEAR}&filter[end]=${FORECAST_DATA_YEAR}&filter[new-age-ranges]=true`,
    );
    return { past: change.data, projected: futureChange.data };
  });

  changeInSchoolAgePopulationByRaceEthnicity = dropTask(async () => {
    let change = await this.network.request(
      `/api/chart-data/change-school-age-population-by-race-ethnicity?filter[zips]=${this.zipParam}&filter[begin]=${PAST_DATA_YEAR}&filter[end]=${CURRENT_DATA_YEAR}`,
    );
    let futureChange = await this.network.request(
      `/api/chart-data/change-school-age-population-by-race-ethnicity?filter[zips]=${this.zipParam}&filter[begin]=${CURRENT_DATA_YEAR}&filter[end]=${FORECAST_DATA_YEAR}`,
    );
    return { past: change.data, projected: futureChange.data };
  });

  setNumAdmissionsListItems(num) {
    this.numAdmissionsListItems = num;
  }

  get hasEnrollmentData() {
    return this.data?.schoolEnrollmentTrendsByZip?.numEnrollments > 0;
  }

  get hasApplicationData() {
    return this.data?.schoolEnrollmentTrendsByZip?.numApplications > 0;
  }

  get hasAdmissionsData() {
    return this.hasEnrollmentData || this.hasApplicationData;
  }

  get hasAnyAdmissionsListItems() {
    return this.numAdmissionsListItems > 0;
  }

  get totalSchoolAgePopulation() {
    let schoolPopByAge = this.data.schoolAgePopulationByAge;
    if (!schoolPopByAge || !schoolPopByAge['0-to-17']) {
      return [];
    }
    return schoolPopByAge['0-to-17'];
  }

  get zipsWithHighestSchoolAgePopulation() {
    let zips = this.zips;
    return (zips || []).sort((a, b) => {
      return b.totalPopulationSchoolAge - a.totalPopulationSchoolAge;
    });
  }

  get zipsWithHighestDiversePopulation() {
    let zips = this.zips;
    return (zips || []).sort((a, b) => {
      return b.populationSchoolAgeOfColor - a.populationSchoolAgeOfColor;
    });
  }

  get zipParam() {
    return asZipParam(this.zips);
  }

  get isLoading() {
    return this.loadBaseData.isRunning || this.loadRouteData.isRunning;
  }
}

function asZipParam(zips) {
  return zips.map((z) => z.zipCode).join(',');
}

export function transform(data) {
  return (data || []).map((d) => {
    return transformKeys(d?.attributes);
  });
}

function transformMeta(meta) {
  return (meta || []).map((d) => {
    return transformKeys(d);
  });
}

function filterString(params) {
  return Object.keys(params)
    .sort()
    .map((key) => `filter[${key}]=${encodeURIComponent(params[key])}`)
    .join('&');
}
