<template>
  <div ...attributes>
    {{#if @isLoading}}
      <section class="loading-animation" role="dialog" data-test-ui-loading>
        <div class="_background">
        </div>
        <div class="_circle">
          <img
            src="/assets/images/loading-animation.gif"
            alt="Loading Animation"
          />
        </div>
      </section>
    {{/if}}
  </div>
</template>
