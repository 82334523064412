import GoogleAnalyticsFour from 'ember-metrics/metrics-adapters/google-analytics-four';

export default class CustomGoogleAnalyticsFour extends GoogleAnalyticsFour {
  trackEvent(options = {}) {
    let event = options?.event ?? `${options.category} ${options.action}`;

    if (!event) {
      return;
    }

    delete options.event;
    delete options.category;
    delete options.action;

    return this.gtag('event', event, options);
  }
}
