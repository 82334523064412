import { set } from '@ember/object';
import { service } from '@ember/service';
import Base from 'ember-simple-auth/authenticators/base';

export default class Nais extends Base {
  @service
  network;

  @service
  metrics;

  @service
  session;

  @service
  store;

  @service
  sentry;

  @service('browser/window') window;

  async authenticate(data) {
    try {
      this.store.unloadAll('user');
      let response = await this.network.baseRequest('/auth', {
        method: 'POST',
        data,
      });

      return await this._initializeSession(response);
    } catch (e) {
      this._clearSession();
      throw e;
    }
  }

  async _initializeSession(response) {
    let userId = response.data.attributes['user-id'];
    let token = response.data.attributes['access-token'];
    let validUntil = response.data.attributes['valid-until'];
    set(this, 'session.token', token);
    set(this, 'session.validUntil', validUntil);
    let user = await this.store.findRecord('user', userId, {
      include: 'school,author,user-preference,school.school-preference',
    });
    this._setContext(user);
    return { token };
  }

  async restore(data) {
    try {
      this.store.unloadAll('user');
      let response = await this.network.baseRequest('/check_token', {
        method: 'POST',
        data: {
          // eslint-disable-next-line camelcase
          access_token: data.token,
        },
      });
      return await this._initializeSession(response);
    } catch (e) {
      this._clearSession();
      throw e;
    }
  }

  _setContext(user) {
    set(this, 'session.currentUser', user);
    this._setMetricsContext(user);
    this._setSentryUser(user);
    this._setChatUser(user);
  }

  _clearSession() {
    set(this, 'session.token', undefined);
    set(this, 'session.currentUser', undefined);
    set(this, 'session.validUntil', undefined);
    this._clearMetricsContext();
    this._clearSentryUser();
  }

  _clearSentryUser() {
    this.sentry.clearUser();
  }

  _setSentryUser(user) {
    const { email, id } = user;
    this.sentry.setUser({ email, id });
  }

  _clearMetricsContext() {
    this.metrics.set('context', {});
  }

  _setMetricsContext(user) {
    const metrics = this.metrics;

    // Using setProperites on `metrics.context` doesn't set the properties
    // so we have to set them individually
    set(metrics, 'context.userID', user.naisUserId);
    set(metrics, 'context.dimension1', user.naisUserId);
    set(metrics, 'context.dimension2', user.school.naisSchoolId);
  }

  _setChatUser(user) {
    /* eslint-disable camelcase */
    this.window.Intercom('update', {
      distinct_id: user.naisUserId,
      email: user.email,
      school_id: user.school.naisSchoolId,
      user_hash: user.intercomHash,
    });
    /* eslint-enable camelcase */
  }
}
