import Application from '@ember/application';
import { importSync, isDevelopingApp, macroCondition } from '@embroider/macros';
import loadInitializers from 'ember-load-initializers';
import Resolver from 'ember-resolver';

import config from 'market-view-ui/config/environment';
import './sentry';
import 'market-view-ui/assets/style.scss';

if (macroCondition(isDevelopingApp())) {
  importSync('./deprecation-workflow');
}
export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);
