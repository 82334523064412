<template>
  {{#if @isSelected}}
    <blockquote>
      <div class="landing__testimonials__quotemark">
        <div>&ldquo;</div>
      </div>
      <p class="landing__testimonials__quote">
        {{@quote}}
      </p>
      <div class="landing__testimonials__attribution">
        <div>
          <p><strong>{{@author}}</strong></p>
          <p>{{@title}}</p>
          <p>{{@school}}</p>
        </div>
      </div>
    </blockquote>
  {{/if}}
</template>
