import Model, { attr } from '@ember-data/model';

export default class AddressModel extends Model {
  @attr('string')
  address;

  @attr('number')
  score;

  @attr('number')
  lat;

  @attr('number')
  long;

  @attr('string')
  source;
}
