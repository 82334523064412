import Model, { attr, belongsTo } from '@ember-data/model';

export default class AdmissionsListItemModel extends Model {
  @attr('string')
  studentId;

  @attr('string')
  firstName;

  @attr('string')
  lastName;

  @belongsTo('user-import', { async: true, inverse: 'admissionsListItems' })
  userImport;

  @belongsTo('address', { async: false, inverse: null })
  address;

  @belongsTo('admissions-list', { async: true, inverse: 'items' })
  admissionsList;

  @belongsTo('grade-level', { async: true, inverse: null })
  gradeLevel;

  get isEnrollment() {
    return this.belongsTo('admissionsList').value().isEnrollments;
  }

  get isApplication() {
    return this.belongsTo('admissionsList').value().isApplications;
  }
}
