import Service, { service } from '@ember/service';
import { task } from 'ember-concurrency';

import { errorMessages, successMessages } from 'market-view-ui/constants';
import base64ToBlob from 'market-view-ui/utils/base64-to-blob';

import { DEFAULT_AGE_RANGES } from '../constants';

export default class ReportActionsService extends Service {
  @service notifications;
  @service network;
  @service location;
  @service router;
  @service log;

  delete = task(async (report) => {
    try {
      await report.destroyRecord();
    } catch (e) {
      this.notifications.error(errorMessages.deleteReport);
      this.log.error(e);

      // Prevents continued execution
      return false;
    }

    this.router.transitionTo('authenticated.reports');
    this.notifications.success(successMessages.deleteReport);
  });

  downloadCsv = task(async (report, title) => {
    let data;

    try {
      data = await this.network.request('/api/reports/create_csv', {
        method: 'POST',
        data: {
          report_params: this.reportParams(report), // eslint-disable-line camelcase
        },
      });
    } catch (e) {
      this.notifications.error(errorMessages.downloadReport);
      this.log.error(e);

      // Prevents continued execution
      return false;
    }

    const blob = base64ToBlob(data.data, 'text/csv');

    const objectUrl = window.URL.createObjectURL(blob);
    let link = document.createElement('a');

    link.href = objectUrl;
    link.download = `${title}.csv`;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(objectUrl);

    this.notifications.success(successMessages.downloadReport);
  });

  downloadPdf = task(async (report, title) => {
    let data;

    try {
      data = await report.downloadPdf();
    } catch (e) {
      this.notifications.error(errorMessages.downloadReport);
      this.log.error(e);

      // Prevents continued execution
      return false;
    }

    const blob = base64ToBlob(data.data, 'application/pdf');

    const objectUrl = window.URL.createObjectURL(blob);
    let link = document.createElement('a');

    link.href = objectUrl;
    link.download = `${title}.pdf`;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(objectUrl);

    this.notifications.success(successMessages.downloadReport);
  });

  reportParams(report) {
    return {
      lat: report?.lat ?? this.location.lat,
      long: report?.long ?? this.location.long,
      address: report?.address ?? this.location.address,
      age_ranges: report?.ageRanges ?? DEFAULT_AGE_RANGES, // eslint-disable-line camelcase
      tuition: report.tuition,
      sex: report?.sex ?? 'all',
      drive_time: report.driveTime, // eslint-disable-line camelcase
    };
  }
}
