/* eslint-disable no-magic-numbers */
import { helper } from '@ember/component/helper';

export function shortSchoolYear(schoolYearOrYear) {
  if (!schoolYearOrYear) {
    return;
  }
  if (typeof schoolYearOrYear == 'string' && schoolYearOrYear.includes('-')) {
    let years = schoolYearOrYear?.split('-');
    return `${years[0]}-${years[1].substring(2, 4)}`;
  } else if (typeof schoolYearOrYear == 'number') {
    let startYear = `${schoolYearOrYear}`;
    let endYear = `${schoolYearOrYear + 1}`;
    return `${startYear.substring(2, 4)}-${endYear.substring(2, 4)}`;
  }
  return schoolYearOrYear;
}

export default helper(function ([year]) {
  return shortSchoolYear(year);
});
