import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class ErrorRoute extends Route {
  @service log;
  setupController(controller, error) {
    this.log.error(error);
    super.setupController(...arguments);
  }
}
