import { capitalize } from '@ember/string';
import Model, { attr, hasMany } from '@ember-data/model';

import { shortSchoolYear } from '../helpers/short-school-year';

export default class AdmissionsListModel extends Model {
  @attr('string')
  kind;

  @attr('string')
  year;

  @attr('number')
  count;

  @hasMany('user-import', { async: false, inverse: null })
  userImports;

  @hasMany('admissions-list-item', { async: true, inverse: 'admissionsList' })
  items;

  get shortYear() {
    return shortSchoolYear(this.year);
  }

  get name() {
    return `${this.shortYear} ${capitalize(this.kind)}`;
  }

  get isEnrollments() {
    return this.kind === 'enrollments';
  }

  get isApplications() {
    return this.kind === 'applications';
  }

  get userUploads() {
    return this.userImports?.filter(
      (userImport) => userImport.isSuccessfulUpload,
    );
  }

  get integrationImports() {
    return this.userImports?.filter(
      (userImport) => userImport.isSuccessfulIntegration,
    );
  }

  get inProgressUploads() {
    return this.userImports?.filter(
      (userImport) => userImport.isUploadInProgress,
    );
  }

  get hasIntegration() {
    return this.hasBlackbaudData || this.hasVeracrossData;
  }

  get hasBlackbaudData() {
    return this.userImports?.find((userImport) => userImport.isBlackbaud);
  }

  get hasVeracrossData() {
    return this.userImports?.find((userImport) => userImport.isVeracross);
  }

  get failedUploads() {
    return this.userImports?.filter(
      (userImport) => userImport.isUploadComplete && userImport.hasFailures,
    );
  }

  get recentFailedUploads() {
    return this.userImports?.filter(
      (userImport) =>
        userImport.isUploadComplete &&
        userImport.hasFailures &&
        userImport.isRecent,
    );
  }
}
