import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class MapLayersService extends Service {
  @service('browser/local-storage')
  localStorage;

  @tracked layers = {};

  isVisible(layerName) {
    let defaultValue = layerName !== 'nearby-schools';
    let value =
      this.layers[layerName] ??
      JSON.parse(this.localStorage.getItem(`layers/${layerName}`)) ??
      defaultValue;
    return value;
  }

  setVisibility(layerName, value) {
    this.localStorage.setItem(`layers/${layerName}`, value);
    this.layers = { ...this.layers, [layerName]: value };
  }
}
