import { LinkTo } from '@ember/routing';
<template>
  <article class="l-page">
    <section class="page__content">
      <LinkTo @route="application" class="page__back-link">
        &larr;Back to Market View
      </LinkTo>
      <header class="page__header">
        <h1 class="page__headline-primary">Market View Terms and Conditions
        </h1>
      </header>
      <h3 class="t-headline-secondary page__headline-secondary">Market View</h3>
      <p class="t-body">NAIS Market View is a dynamic platform intended to help
        schools better understand their market and identify the number and
        approximate location of prospective families within the school’s
        geographical area.</p>

      <h3 class="t-headline-secondary page__headline-secondary">Market View
        Rules, Restrictions, and Policies</h3>
      <p class="t-body">These Terms and Conditions govern your use of Market
        View. Users shall follow all of the rules, restrictions, guidelines, and
        policies provided in the Market View Terms and Conditions, as may be
        modified from time to time by NAIS. By using this tool, the user
        warrants that s/he has read these rules, restrictions, and policies and
        agrees to all of the Terms and Conditions.</p>
      <p class="t-body">Please check these Terms and Conditions regularly
        because we may modify them from time to time by posting modified Terms
        and Conditions on this page or, if we determine that it is appropriate,
        by giving you additional notice of the changes. Your continued use of
        Market View after we have posted amended Terms and Conditions indicates
        that you agree to the changes. If you have questions about the Terms and
        Conditions, please submit a
        <a href="mailto:membership@nais.org">request</a>.</p>

      <h3 class="t-headline-secondary page__headline-secondary">Other Terms and
        Conditions Governing this Agreement</h3>
      <p class="t-body">These Market View Terms and Conditions supplement and
        incorporate the
        <a
          href="https://www.nais.org/about/nais-terms-and-conditions-notice/"
          target="_blank"
          rel="noopener noreferrer"
        >NAIS Terms and Conditions Policy</a>. To the extent that these Terms
        and Conditions conflict with any other rules, regulations, guidelines,
        or policies provided through the NAIS website, these Terms and
        Conditions herein shall control with respect to Market View, its uses,
        and data.</p>

      <h3 class="t-headline-secondary page__headline-secondary">Use of Market
        View and Its Contents</h3>
      <p class="t-body">Eligible users of Market View include individuals
        authorized by NAIS–member schools and premium subscribers. User
        understands that it is their sole responsibility to and warrants that
        they have obtained appropriate authorization to provide the data
        outlined below to NAIS for use in Market View in accordance with their
        school’s agreements, policies, and procedures.</p>
      <p class="t-body">Each user can upload data and set up data integrations
        with their admissions software. Data is sorted into three categories:
        miscellaneous data, lists of students who applied to their school, and
        lists of students who have enrolled in their school. Student data has
        the following fields: first name; last name; grade; student ID; and
        address. Users can generate maps, data tables, and data visualizations
        (“reports”), and save them to their local drive.</p>
      <p class="t-body">Each member school and premium subscriber may have
        multiple Market View users and reports will be shared and accessible for
        all users associated with the same school. Additionally, once a data
        integration is set up by one user, the resultant data and reports will
        be available for all users associated with the same school. Users will
        also have access to NAIS created community reports, including those
        specific to their area and market.</p>
      <p class="t-body">You may not use Market View or any of its contents in
        any way that would violate (i) laws or regulations, including but not
        limited to antitrust laws or (ii) the rights of the NAIS or any third
        party. NAIS will not share, sell, or otherwise distribute user uploaded
        or integrated lists to third parties.</p>

      <h3 class="t-headline-secondary page__headline-secondary">Intellectual
        Property</h3>
      <p class="t-body">Users acknowledge that Market View, its program, marks
        and logos, interactive maps, data visualizations, and resultant data in
        whatever form are the intellectual property of NAIS. Users participating
        in Market View are granted a limited, non-exclusive, revocable,
        non-assignable, non-transferable license to use Market View generated
        reports, maps, visualizations, and data appropriately for school needs.</p>

      <h3
        class="t-headline-secondary page__headline-secondary"
      >Indemnification</h3>
      <p class="t-body">User agrees to defend, indemnify, and hold NAIS harmless
        from and against any and all claims, demands, or causes of action of
        third parties and liabilities, judgments, penalties, losses, costs,
        damages, and expenses resulting directly there from, including
        reasonable attorneys' fees, arising by reason of or in connection with
        any act or omission pursuant to or in breach of these Terms and
        Conditions by the User, School, its agents, employees, board of
        directors, or others reasonably associated with the school.</p>
      <p class="t-body">NAIS does not warrant or guarantee the accuracy of any
        of the information found in Market View, nor does it warrant or
        guarantee that the resultant information, interactive maps, data
        visualizations, or reports created by the user is appropriate for any
        particular purpose, including meeting particular enrollment goals or
        revenue targets. Further, NAIS does not warrant or guarantee the
        appropriateness of the use of this site by other independent schools and
        other users. NAIS is NOT responsible for any consequences or damages
        resulting from errors or inaccuracies in the data or the use or
        operation of this site by the school or others.</p>

      <h3 class="t-headline-secondary page__headline-secondary">Acknowledgement,
        Acceptance, and Release of Liability
      </h3>
      <p class="t-body">By using this tool and accepting these terms and
        conditions the school hereby releases NAIS of any liability resulting
        from improper or proper use of this information by the user, school, its
        agents, or any other individual or entity. NAIS does not guarantee or
        warrant in any way the accuracy of the information provided through this
        service.</p>

      <h3 class="t-headline-secondary page__headline-secondary">Privacy:
        Information We Collect and Use—First Party Cookies and Server
        Information</h3>
      <p class="t-body"><strong>Analytics:</strong>
        NAIS uses Google Analytics (including Universal Analytics and
        Demographics and Interest Reporting) on the Market View application.
        Google Analytics tracks users on the web and can provide general,
        anonymized information about individual users in various categories.
        This information does not provide NAIS with personal information, but it
        does give us a better sense of who is visiting our website in terms of
        general demographics. Third parties that support NAIS by serving
        advertisements or providing services, such as allowing you to share
        content or tracking aggregate usage statistics, (e.g.,
        <a
          href="https://tools.google.com/dlpage/gaoptout/"
          target="_blank"
          rel="noopener noreferrer"
        >Google Analytics</a>
        tracking and
        <a
          href="https://support.google.com/adwordspolicy/bin/answer.py?answer=143465"
          target="_blank"
          rel="noopener noreferrer"
        >Google AdWords</a>) may also use these technologies to collect similar
        information. Google Analytics does not collect personally identifiable
        information to share with NAIS or others, and NAIS will not share your
        personally identifiable information with Google Analytics or its
        partners unless we have received specific consent from you. If you are
        still worried, you can
        <a
          href="https://tools.google.com/dlpage/gaoptout/"
          target="_blank"
          rel="noopener noreferrer"
        >opt out of Google Analytics</a>. To opt out of third-party vendor
        cookies, see
        <a
          href="https://www.networkadvertising.org"
          target="_blank"
          rel="noopener noreferrer"
        >the Network Advertising Initiative’s consumer opt-out page</a>.</p>
      <p class="t-body"><strong>System and Data Security:</strong>
        You consent to and authorize NAIS, in accordance with applicable law(s),
        to debug, monitor, or capture the full in-app user activity, and
        otherwise inspect or investigate all user access of Market View or
        Market View data for security and systems operations purposes. For
        clarity, and without limiting the foregoing, this consent and
        authorization extends to the School and their eligible user and access
        of all files, usage logs, content and all other Market View activity of
        the School, its eligible users, or users (authorized or not) connected
        to its account. NAIS may, in NAIS’s sole discretion, restrict access to
        and use of Market View and delete, remove, or disable any content or
        action that is unauthorized, in violation of these Market View Terms or
        Conditions, or is deemed a data security risk. You acknowledge and agree
        that you shall not have any expectation of privacy as to your use and
        access of Market View and acknowledge that NAIS has authority for full
        control and monitoring of all such use or access for this purpose.</p>

      <h3 class="t-headline-secondary page__headline-secondary">For More
        Information, Questions, or Concerns</h3>
      <p class="t-body">For more information about Market View, or to ask a
        question, please open a ticket in our support center:
        https://my.nais.org/s/help-center or by mail NAIS Market View, 2001 K
        Street NW, Suite 1150, Washington, DC 20006, or phone 202-973-9700.</p>

      <h3 class="page__headline-secondary">Last Updated: March 4, 2024</h3>
    </section>
  </article>
</template>
