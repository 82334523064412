import Service, { service } from '@ember/service';

export default class LocalStorage extends Service {
  @service('browser/local-storage') localStorage;

  save(item, value) {
    return this.localStorage.setItem(item, value);
  }

  fetch(item) {
    return this.localStorage.getItem(item);
  }

  remove(item) {
    return this.localStorage.removeItem(item);
  }
}
