import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class Collection extends Model {
  @attr('string')
  numAdmissionsListItems;

  @belongsTo('school', { async: true, inverse: 'collection' })
  school;

  @hasMany('report', { async: true, inverse: 'collection' })
  reports;
}
