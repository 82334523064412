import Service from '@ember/service';
import * as Sentry from '@sentry/ember';

import config from 'market-view-ui/config/environment';

export default class SentryService extends Service {
  error() {
    if (!this.enabled) {
      return;
    }
    Sentry.captureException(...arguments);
  }

  setUser(user) {
    if (!this.enabled) {
      return;
    }
    Sentry.setUser(user);
  }

  clearUser() {
    if (!this.enabled) {
      return;
    }
    Sentry.setUser(null);
  }

  get enabled() {
    return config.sentry.enabled;
  }
}
