import defaultTo from 'market-view-ui/helpers/default-to';
import vimeo from 'market-view-ui/modifiers/vimeo';
<template>
  <iframe
    {{vimeo onPlay=@onPlay onPause=@onPause}}
    class="video-player"
    src={{@videoUrl}}
    width={{@width}}
    height={{defaultTo @height "400px"}}
    frameborder="0"
    webkitallowfullscreen
    mozallowfullscreen
    allowfullscreen
    allow="autoplay; encrypted-media"
    title="A video"
    ...attributes
  ></iframe>
</template>
