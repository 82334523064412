import Service, { service } from '@ember/service';
import { restartableTask } from 'ember-concurrency';

import transformKeys from '../utils/transform-keys';

export default class ReportService extends Service {
  @service
  network;

  @service
  store;

  async fetch(reportOptions) {
    return await this.network.request('/api/reports/generate', {
      method: 'POST',
      data: Object.assign({ format: 'geojson' }, reportOptions),
    });
  }

  fetchNcesSchools = restartableTask(async (zipCodes) => {
    return await this.store.query('nces-school', { zip_codes: zipCodes }); // eslint-disable-line camelcase
  });

  fetchSchoolTrends = restartableTask(async (zipCodes) => {
    let data = await this.network.request(
      `/api/school-trends/recent?filter[zips]=${zipCodes.join(',')}`,
    );
    if (data) {
      return transformKeys(data);
    }
  });

  fetchAdmissionsListItems = restartableTask(async (zipCodes) => {
    let items = await this.store.query('admissions-list-item', {
      filter: { zips: zipCodes.join(','), recent: true },
      include: 'address,admissions-list',
    });
    let data = {};
    items.forEach((item) => {
      let list = item.belongsTo('admissionsList').value();
      if (!data[list.id]) {
        data[list.id] = {
          id: list.id,
          name: list.name,
          items: [],
        };
      }
      data[list.id].items.push(item);
    });
    return data;
  });
}
