import { typeOf } from '@ember/utils';
import Transform from '@ember-data/serializer/transform';

export default class ObjectTransform extends Transform {
  deserialize(serialized) {
    return this._transform(serialized);
  }

  serialize(deserialized) {
    return this._transform(deserialized);
  }

  _transform(value) {
    return typeOf(value) === 'object' ? value : null;
  }
}
