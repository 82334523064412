import BaseAdapter from 'ember-metrics/metrics-adapters/base';

export default class LocalAdapter extends BaseAdapter {
  toStringExtension() {
    return 'local';
  }

  install() {}

  identify(options = {}) {
    console.log('Metrics:', 'identify', options);
  }

  trackEvent(options = {}) {
    console.log('Metrics:', 'trackEvent', options);
    if (options.hitCallback) {
      options.hitCallback();
    }
  }

  trackPage(options = {}) {
    console.log('Metrics:', 'trackPage', options);
  }

  alias(options = {}) {
    console.log('Metrics:', 'alias', options);
  }

  uninstall() {}
}
