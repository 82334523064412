import { service } from '@ember/service';
import Model, { attr } from '@ember-data/model';

export default class CommunityReport extends Model {
  @service network;

  @attr('date')
  lastModified;

  get name() {
    let filename = this.id.split('/')[this.id.split('/').length - 1];
    filename = filename.replace('_', ' ');
    filename = filename.replace('.pdf', '');
    return filename.replace(
      /\w\S*/g,
      (word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase(),
    );
  }

  async downloadPdf() {
    return await this.network.request(
      `/api/community-reports/${encodeURIComponent(this.id)}/download`,
      {
        method: 'POST',
      },
    );
  }
}
