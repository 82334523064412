import Model, { attr, belongsTo } from '@ember-data/model';

export default class User extends Model {
  @attr('string')
  username;

  @attr('string')
  email;

  @attr('string')
  firstName;

  @attr('string')
  lastName;

  @attr('string')
  naisUserId;

  @attr('string')
  intercomHash;

  @belongsTo('school', { async: false, inverse: null })
  school;

  @belongsTo('author', { async: false, inverse: 'user' })
  author;

  @belongsTo('user-preference', { async: false, inverse: 'user' })
  userPreference;

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }
}
