import EmberRouter from '@embroider/router';
import { withHashSupport } from 'ember-url-hash-polyfill';

import config from 'market-view-ui/config/environment';

@withHashSupport
export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('auth');
  this.route('authenticated', { path: '' }, function () {
    this.route('map');
    this.route('reports', function () {
      this.route(
        'report',
        {
          path: ':report_id',
        },
        function () {
          this.route('rename');
          this.route('share');
          this.route('details');
        },
      );
    });
    this.route('trends', function () {
      this.route('affordability', function () {
        this.route('median');
        this.route('high-income');
        this.route('distribution');
        this.route('race-ethnicity');
        this.route('change-in-average');
      });
      this.route('student-body', function () {
        this.route('private-school');
        this.route('change');
      });
      this.route('diversity', function () {
        this.route('ethnicity');
        this.route('change');
        this.route('compare-zips');
      });

      this.route('index', { path: '/' }, function () {
        this.route('population', { path: '/' });
        this.route('income', { path: '/summary/income' });
        this.route('diversity', { path: '/summary/diversity' });
      });
      this.route('enrollment', function () {
        this.route('trends', function () {
          this.route('applications');
          this.route('enrollments', { path: '/' });
          this.route('diversity');
          this.route('financial-aid');
        });
        this.route('opportunities');
      });
    });

    this.route('zips', function () {
      this.route('zip', { path: ':zip_code' });
    });

    this.route('data', { path: '/your-data' }, function () {
      this.route('index', { path: '/' }, function () {
        this.route('index', { path: '/' });
        this.route('miscellaneous');
      });
      this.route('dataset', { path: '/dataset/:id' });
      this.route('admissions-list', { path: '/admissions-list/:kind/:year' });
      this.route('upload', { path: '/uploads/:id' });
      this.route('integration', { path: '/integrations/:source' });
      this.route('dasl');
      this.route('veracross', function () {
        this.route('index', { path: '/' });
        this.route('connect');
      });
      this.route('blackbaud', function () {
        this.route('index', { path: '/' });
        this.route('connect');
      });

      this.route('finalsite', function () {
        this.route('connect');
      });
      this.route('beta');
    });

    this.route('callbacks', function () {
      this.route('blackbaud');
    });
    this.route('comparisons', function () {
      this.route('resources', function () {
        this.route('index', { path: '/' }, function () {
          this.route('index', { path: '/' });
          this.route('page', { path: '/page/:page' });
        });
        this.route('terms');
      });

      this.route('index', { path: '/' }, function () {
        this.route('financial-sustainability', { path: '/' });
        this.route('tuition-discounting');
        this.route('financial-aid-applicants');
      });
    });
  });
  this.route('index', { path: '/' });
  this.route('terms');
  this.route('404', { path: '/*path' });
  this.route('about-our-data');
});
