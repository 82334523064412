import Service, { service } from '@ember/service';

import { errorMessages } from 'market-view-ui/constants';

import { apiURL } from '../utils/api-url';

const Unauthorized = 401;
const NoContent = 204;

export default class NetworkService extends Service {
  @service('session')
  session;

  @service notifications;

  async #fetch(url, options) {
    try {
      return await fetch(url, options);
    } catch (e) {
      throw new NetworkError(e.message, { cause: e });
    }
  }

  async baseRequest(url, options = {}) {
    let defaultOptions = {
      headers: {
        Authorization: this.session.token,
        'Content-Type': 'application/vnd.api+json',
      },
    };

    let opts = Object.assign(defaultOptions, options);

    if (opts.data) {
      opts.body = JSON.stringify(opts.data);
      delete opts.data;
    }
    let response = await this.#fetch(`${apiURL()}${url}`, opts);
    return await handleResponse(response, resolveJSON);
  }

  /*
    Make requests to the API where we want to handle 401s by invalidating the session.
    Should be used for everything except for authenticator requests.
  */
  async request(url, options = {}) {
    try {
      return await this.baseRequest(url, options);
    } catch (e) {
      if (
        e instanceof ResponseError &&
        e.status &&
        e.status === Unauthorized &&
        this.session.isAuthenticated
      ) {
        await this.session.invalidate();
        this.notifications.error(errorMessages['401']);
      } else {
        throw e;
      }
    }
  }

  async fetchText(url, options = {}) {
    try {
      const response = await this.#fetch(url, options);
      return await handleResponse(response, resolveText);
    } catch (e) {
      this.notifications.error(errorMessages['unknown']);
      throw e;
    }
  }

  async fetchJSON(url, options = {}) {
    try {
      const response = await this.#fetch(url, options);
      return await handleResponse(response, resolveJSON);
    } catch (e) {
      this.notifications.error(errorMessages['unknown']);
      throw e;
    }
  }
}

async function resolveJSON(response) {
  try {
    return response.json();
  } catch (e) {
    throw new JSONParseError(e.message, { cause: e });
  }
}

async function resolveText(response) {
  try {
    return response.text();
  } catch (e) {
    throw new TextParseError(e.message, { cause: e });
  }
}

export async function handleResponse(response, resolvePayload = resolveJSON) {
  if (response.status === NoContent) {
    return;
  }
  const payload = await resolvePayload(response);
  if (response.ok) {
    return payload;
  } else {
    throw new ResponseError(response, payload, response.status);
  }
}

const NETWORK_ERROR_MESSAGE = 'Network or CORS error:';
export class NetworkError extends Error {
  constructor(message, options) {
    super(
      `${NETWORK_ERROR_MESSAGE} Online: ${window.navigator.onLine} Message: ${message}`,
      options,
    );
  }
}
class JSONParseError extends Error {}

class TextParseError extends Error {}

export class ResponseError extends Error {
  response;
  payload;
  status;

  constructor(response, payload, status) {
    super(`Response Error: ${status} in response to ${response.url}`);
    this.response = response;
    this.payload = payload;
    this.status = status;
  }
}
