import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class LocationService extends Service {
  @service
  session;

  @service
  log;

  @tracked _lat = null;
  @tracked _long = null;
  @tracked _address = null;

  @tracked
  recentLocations = [];

  get lat() {
    return this._lat || this.school?.lat;
  }

  set lat(value) {
    this._lat = value;
  }

  get long() {
    return this._long || this.school?.long;
  }

  set long(value) {
    this._long = value;
  }

  get address() {
    return this._address || this.school?.address;
  }

  set address(value) {
    this._address = value;
  }

  get centerpoint() {
    return {
      address: this.address,
      coordinates: [this.long, this.lat],
    };
  }

  set centerpoint(value) {
    this.log.info('Location Service: Setting centerpoint');
    if (!(value.coordinates && value.coordinates[0] && value.coordinates[1])) {
      this.log.error(
        new Error('Location Service: Setting coordinates to undefined'),
      );
    }
    this.recentLocations = [value, ...this.recentLocations];
    this.address = value.address;
    this.long = value.coordinates[0];
    this.lat = value.coordinates[1];
  }

  get centerpointIsAtSchool() {
    let school = this.session?.currentUser?.school;
    return school.address === this.address;
  }

  get cleanAddress() {
    if (this.address) {
      let parts = this.address.split(',');
      if (parts.length === 1) {
        return this.address;
      }
      return `${parts[0]}, ${parts[1]}`;
    }
    return this.address;
  }

  get school() {
    return this.session.currentUser && this.session.currentUser.school;
  }
}
