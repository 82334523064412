import { modifier } from 'ember-modifier';

export default modifier(
  function clickOutsideIfActive(element, [action], { isActive }) {
    if (isActive) {
      let documentClick = function (e) {
        if (e.target !== element && !element.contains(e.target)) {
          action(e);
        }
      };
      document.addEventListener('click', documentClick);

      return () => {
        document.removeEventListener('click', documentClick);
      };
    }
  },
  { eager: false },
);
