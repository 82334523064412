import Model, { attr, belongsTo } from '@ember-data/model';

export default class School extends Model {
  @attr('string')
  name;

  @attr('string')
  address;

  @attr('number')
  lat;

  @attr('number')
  long;

  @attr('string')
  naisSchoolId;

  @attr('string')
  cbsaId;

  @attr('boolean')
  canChangeCenterpoint;

  @belongsTo('collection', { inverse: 'school', async: true })
  collection;

  @belongsTo('school-preference', { async: false, inverse: 'school' })
  schoolPreference;

  get location() {
    return [this.long, this.lat];
  }

  get centerpoint() {
    return {
      address: this.address,
      coordinates: this.location,
    };
  }
}
