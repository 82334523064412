import Route from '@ember/routing/route';
import { service } from '@ember/service';

import breakpoints from '../breakpoints';

export default class ApplicationRoute extends Route {
  @service
  session;

  @service
  metrics;

  @service
  router;

  @service
  media;

  @service('browser/window') window;

  constructor() {
    super(...arguments);
    let router = this.router;
    router.on('routeDidChange', () => {
      let page = router.currentURL;
      let title = router.currentRouteName || 'unknown';

      this.metrics.trackPage({ page, title });
    });

    /* eslint-disable camelcase */
    this.window.Intercom('boot', {
      app_id: 'ugaimzri',
      custom_launcher_selector: '.js-intercom-launcher',
      alignment: 'left',
      horizontal_padding: 20,
      vertical_padding: 20,
    });
    /* eslint-enable camelcase */
  }

  async beforeModel() {
    this.media.setBreakpoints(breakpoints);
    await this.session.setup();
  }
}
