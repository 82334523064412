import { hash } from '@ember/helper';
import { on } from '@ember/modifier';
import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import featureFlag from 'ember-feature-flags/helpers/feature-flag';
import media from 'ember-media-query/helpers/media';
import svgJar from 'ember-svg-jar/helpers/svg-jar';

import LogoutButton from 'market-view-ui/components/logout-button';
import trackEvent from 'market-view-ui/helpers/track-event';

export default class NavBar extends Component {
  @service location;
  <template>
    <nav class="l-nav {{if @showNav 'has-nav-showing'}}" ...attributes>
      {{#unless (media "desktop")}}
        <div class="nav-links">
          <LinkTo
            @route="authenticated.trends"
            class="nav-links__link"
            {{on "click" @toggleNav}}
            @activeClass="map-info__toggle-link--is-active"
            data-test-nav-bar-link-trends
          >
            {{svgJar
              "bar-chart"
              aria-hidden="true"
              class="nav-links__icon"
              height="18px"
              width="48px"
            }}
            Trends
          </LinkTo>
          <LinkTo
            @route="authenticated.map"
            @query={{hash
              address=this.location.address
              lat=this.location.lat
              long=this.location.long
            }}
            class="nav-links__link"
            {{on "click" @toggleNav}}
            @activeClass="map-info__toggle-link--is-active"
            data-test-nav-links-map
          >
            {{svgJar
              "map"
              aria-hidden="true"
              class="nav-links__icon"
              height="16px"
              width="48px"
            }}
            Maps
          </LinkTo>

          {{#if (featureFlag "comparisons")}}
            <LinkTo
              @route="authenticated.comparisons"
              class="nav-links__link"
              {{on "click" @toggleNav}}
              @activeClass="map-info__toggle-link--is-active"
              data-test-nav-bar-link-comparisons
            >
              {{svgJar
                "bar-chart"
                aria-hidden="true"
                class="nav-links__icon"
                height="18px"
                width="48px"
              }}
              Comparisons
            </LinkTo>
          {{/if}}

          <LinkTo
            @route="authenticated.data"
            class="nav-links__link nav-links__addresses"
            {{on "click" @toggleNav}}
            @activeClass="map-info__toggle-link--is-active"
            data-test-nav-bar-link-data
          >
            <div class="nav-links__addresses-icon">
              {{svgJar
                "map-marker"
                class="nav-links__icon"
                width="18px"
                aria-hidden=true
              }}
              {{svgJar
                "map-marker"
                class="nav-links__icon"
                width="18px"
                aria-hidden=true
              }}
              {{svgJar
                "map-marker"
                class="nav-links__icon"
                width="18px"
                aria-hidden=true
              }}
            </div>
            Your Data
          </LinkTo>
        </div>
      {{/unless}}

      <div class="nav-footer">
        <a
          class="nav-footer__link"
          href="https://market-view.nolt.io/newest"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{svgJar
            "star-filled"
            aria-hidden="true"
            class="nav-footer__icon"
            height="12px"
            width="20px"
          }}
          Request a New Feature
        </a>

        <a
          class="nav-footer__link"
          href="https://docs.marketview.nais.org/nais"
          target="_blank"
          rel="noopener noreferrer"
          {{on
            "click"
            (trackEvent "User" "Help Viewed" "from side nav" isOutbound=true)
          }}
        >
          {{svgJar
            "announcement"
            aria-hidden="true"
            class="nav-footer__icon"
            height="12px"
            width="20px"
          }}
          Help
        </a>

        <LinkTo
          @route="about-our-data"
          class="nav-footer__link"
          {{on "click" @toggleNav}}
        >
          {{svgJar
            "question-circle"
            aria-hidden="true"
            class="nav-footer__icon"
            height="12px"
            width="20px"
          }}
          About Our Data
        </LinkTo>

        <LinkTo
          @route="terms"
          class="nav-footer__link"
          {{on "click" @toggleNav}}
        >
          {{svgJar
            "info"
            aria-hidden="true"
            class="nav-footer__icon"
            height="12px"
            width="20px"
          }}
          Terms of Service
        </LinkTo>

        <LogoutButton class="nav-footer__link nav-footer__logout">
          {{svgJar
            "close"
            aria-hidden="true"
            class="nav-footer__icon"
            height="12px"
            width="20px"
          }}
          Log out
        </LogoutButton>
        <a href="http://nais.org/" target="_blank" rel="noreferrer noopener">
          <img
            class="nav-footer__logo"
            src="/assets/svgs/nais.svg"
            width="170"
            height="26"
            alt="Market View by NAIS"
          />
        </a>
      </div>
    </nav>
  </template>
}
