import Model, { attr } from '@ember-data/model';

export default class NcesSchool extends Model {
  @attr('string')
  name;

  @attr('string')
  website;

  @attr('number')
  lat;

  @attr('number')
  long;

  @attr('string')
  address;

  @attr('string')
  zip;

  @attr('string')
  schoolType;

  @attr('string')
  charterSchool;

  @attr('string')
  magnetSchool;

  @attr('string')
  gradesOffered;

  get url() {
    const website = this.website;
    if (website) {
      const expression =
        /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi; // eslint-disable-line
      const regex = new RegExp(expression);
      const match = website.match(regex);
      if (match) {
        let firstMatch = match[0];
        firstMatch = firstMatch.replace('http://', '').replace('https://', '');
        return `http://${firstMatch}`;
      }
    }
    return null;
  }
}
