import { contributionAtIncome } from './contribution-at-income';
import roundToNearestThousandToConveyUncertaintyInCalculations from './round-to-nearest-thousand-to-convey-uncertainty-in-calculations';

export default function computeFinancialGap(
  tuition,
  income,
  ContributionCalc = contributionAtIncome,
) {
  if (tuition == undefined || income == undefined) {
    return null;
  }
  let contribution = ContributionCalc(income);
  let gap = Math.max(tuition - contribution, 0);
  return Math.min(
    roundToNearestThousandToConveyUncertaintyInCalculations(gap),
    tuition,
  );
}
