import Service, { service } from '@ember/service';
import { restartableTask, dropTask, task, all } from 'ember-concurrency';
import { hash } from 'rsvp';

import { errorMessages, successMessages } from 'market-view-ui/constants';

export default class DataService extends Service {
  @service store;
  @service log;
  @service router;
  @service notifications;
  @service metrics;
  @service uploads;
  @service downloads;

  load = restartableTask(async () => {
    let miscellaneous = this.store.query('dataset', {
      include: 'dataitems,user-import,user-import.failures',
    });

    let admissionsLists = this.store.findAll('admissions-list', {
      include: 'user-imports,user-imports.failures',
    });

    await hash({ miscellaneous, admissionsLists });

    let inProgressAdmissionsImports = admissionsLists
      ?.map((list) => list.inProgressUploads)
      .flat();

    let inProgressMiscellaneousImports = miscellaneous
      ?.map((dataset) =>
        dataset.isUploadInProgress ? dataset?.userImport : null,
      )
      .filter((n) => n);

    this.uploads.addInProgressUploads([
      ...inProgressAdmissionsImports,
      ...inProgressMiscellaneousImports,
    ]);

    let completedMiscellaneous = miscellaneous.filter(
      (dataset) => dataset?.userImport?.isSuccessfulUpload,
    );

    let integrations = await this.store.findAll('integration');

    return {
      miscellaneous: completedMiscellaneous,
      admissionsLists,
      integrations,
    };
  });

  deleteAndReturnToIndex = dropTask(async (model, indexRoute, metrics) => {
    try {
      await model.destroyRecord();
    } catch (e) {
      this.notifications.error(errorMessages.deleteFile);
      this.log.error(e);
      return false;
    } finally {
      this.metrics.trackEvent(metrics);
    }
    await this.router.replaceWith(indexRoute);
    this.notifications.success(successMessages.deleteFile);
  });

  rename = dropTask(async (model, metrics, fileName) => {
    this.metrics.trackEvent(metrics);
    try {
      model.name = fileName;
      await model.save();
      this.notifications.success(successMessages.renameFile);
    } catch (e) {
      model.rollbackAttributes();
      this.notifications.error(errorMessages.renameFile);
      this.log.error(e);
    }
  });

  downloadFailedRows = task(async (failedImport, metricsLabel) => {
    try {
      this.metrics.trackEvent({
        category: 'Your Data',
        action: 'Download Failed Rows',
        label: metricsLabel,
      });

      let rows = importFailuresAsRows(failedImport);
      this.downloads.downloadBlob(
        arrayToCsv(rows),
        'text/csv',
        `Failed rows from ${failedImport.source?.replace('.csv', '')}.csv`,
      );
      let destroyingFailures = (failedImport.failures || []).map((failure) =>
        failure.destroyRecord(),
      );
      await all(destroyingFailures);
      this.notifications.success(successMessages.downloadFailedRows);
    } catch (e) {
      this.log.error(e);
      this.notifications.error(errorMessages.downloadFailedRows);
    }
  });

  deleteFailedRows = task(async (failedImport, metricsLabel) => {
    try {
      this.metrics.trackEvent({
        category: 'Your Data',
        action: 'Delete Failed Rows',
        label: metricsLabel,
      });

      let destroyingFailures = failedImport.failures.map((failure) =>
        failure.destroyRecord(),
      );
      await all(destroyingFailures);
      this.notifications.success(successMessages.deleteFailedRows);
    } catch (e) {
      this.log.error(e);
      this.notifications.error(errorMessages.deleteFailedRows);
    }
  });
}

function importFailuresAsRows(failedImport) {
  const fields = ['Source', 'Line Number', 'Import Failure Reason'];
  let rows = (failedImport?.failures || [])
    .slice()
    .sort((a, b) => {
      return a.line - b.line;
    })
    .map((failure) => [failure.source, failure.line, failure.reason]);
  rows.unshift(fields);
  return rows;
}

function arrayToCsv(rows) {
  return rows
    .map((row) =>
      row
        .map((value) => String(value)?.replaceAll('"', '""'))
        .map((value) => `"${value}"`)
        .join(','),
    )
    .join('\r\n');
}
