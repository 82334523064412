import BasicDropdownWormhole from 'ember-basic-dropdown/components/basic-dropdown-wormhole';
import FlashMessage from 'ember-cli-flash/components/flash-message';
import NewVersionNotifier from 'ember-cli-new-version/components/new-version-notifier';
import pageTitle from 'ember-page-title/helpers/page-title';

import HeaderBar from 'market-view-ui/components/header-bar';
import NotificationToast from 'market-view-ui/components/notification-toast';

<template>
  {{pageTitle "NAIS Market View"}}
  <div class="market-view-application">
    {{#if @controller.session.isAuthenticated}}
      <HeaderBar />
    {{/if}}

    {{#each @controller.flashMessages.queue as |flashItem|}}
      <FlashMessage @flash={{flashItem}} as |component content close|>
        <NotificationToast
          @close={{close}}
          @content={{content}}
          class="test-notification"
        />
      </FlashMessage>
    {{/each}}

    <NewVersionNotifier
      @updateMessage="A new version of Market View is available! Reload for the latest updates. "
    />

    {{outlet}}
  </div>
  <BasicDropdownWormhole />
</template>
