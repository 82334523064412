import Service, { service } from '@ember/service';

export default class LogService extends Service {
  @service sentry;

  error(e) {
    this.sentry.error(e);
    console.error(e);
  }

  info() {
    console.log(...arguments);
  }
}
