import Model, { attr, belongsTo } from '@ember-data/model';

export default class UserPreference extends Model {
  @attr('object')
  showTips;

  @attr('array')
  favoriteZipCodes;

  @attr('array')
  hiddenZipCodes;

  @belongsTo('user', { async: false, inverse: 'userPreference' })
  user;
}
