import { setProperties, action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { errorMessages } from 'market-view-ui/constants';

const Unauthorized = 401;

export default class AuthRoute extends Route {
  @service session;

  @service log;

  @service notifications;

  @service router;

  @service('browser/window') window;

  async beforeModel() {
    let {
      location: { href },
    } = this.window;
    let [, token] = href.match(/token=([^&]*)/);

    await this.session.authenticate('authenticator:nais', { token });
  }

  @action
  error(e) {
    let errorObj = {};
    // e.payload is sometimes a promise!!!
    if (e.status === Unauthorized && e.payload) {
      const error = e.payload?.errors?.[0];
      if (error?.detail === 'Only USA Supported') {
        setProperties(errorObj, errorMessages.outsideUS);
      } else {
        // No email or school found
        setProperties(errorObj, errorMessages.unauthorized);
      }
    } else {
      this.log.error(e);
      setProperties(errorObj, errorMessages.unknown);
    }

    this.notifications.error(errorObj);
    this.router.transitionTo('application');
  }
}
