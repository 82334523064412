import Transform from '@ember-data/serializer/transform';
export default class ArrayTransform extends Transform {
  deserialize(serialized, options) {
    if (!serialized || !serialized.length || !Array.isArray(serialized)) {
      if (options && options.defaultValue) {
        return options.defaultValue();
      }
      return [];
    }
    return serialized;
  }

  serialize(deserialized) {
    return deserialized && deserialized.length && Array.isArray(deserialized)
      ? deserialized
      : [];
  }
}
