import { camelize } from '@ember/string';

export default function transformKeys(data, { topLevelOnly = false } = {}) {
  let t = {};
  if (!data) {
    return data;
  }
  Object.keys(data).forEach((key) => {
    if (
      !topLevelOnly &&
      typeof data[key] === 'object' &&
      !Array.isArray(data[key]) &&
      data[key] !== null
    ) {
      t[camelize(key)] = transformKeys(data[key]);
    } else {
      t[camelize(key)] = data[key];
    }
  });
  return t;
}
