export function compactCurrency(value) {
  return inThousands(value, (val) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(val),
  );
}

export function compactNumber(value) {
  return inThousands(value, (val) =>
    new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(val),
  );
}

function inThousands(value, formatter) {
  let localVal = value;
  let thousand = 1000;
  let over1k = value >= thousand;
  if (over1k) {
    localVal = value / thousand;
  }

  let formatted = formatter(localVal);

  if (over1k) {
    return `${formatted}K`;
  }

  return formatted;
}
