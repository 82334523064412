import { on } from '@ember/modifier';
import Component from '@glimmer/component';
import svgJar from 'ember-svg-jar/helpers/svg-jar';

export default class NotificationToast extends Component {
  get isError() {
    return this.args.content.type === 'error';
  }

  get isSuccess() {
    return this.args.content.type === 'success';
  }
  <template>
    <aside
      class="l-toast
        {{if this.isError 'is--error'}}
        {{if this.isSuccess 'is--success'}}"
      ...attributes
    >
      <div class="toast__icon-wrap" aria-hidden="true">
        {{svgJar
          @content.icon
          class="svg-icon"
          width="24px"
          aria-hidden="true"
        }}
      </div>

      <header class="toast__header">
        <h1 class="toast__headline" data-test-notification-headline>
          {{@content.headline}}
        </h1>

        <h2 class="toast__subhead" data-test-notification-message>
          {{@content.message}}
        </h2>
      </header>

      <button
        class="toast__close"
        aria-label="Close Notification"
        {{on "click" @close}}
        data-test-notification-close
      >
        {{svgJar
          "close"
          class="svg-icon toast__close-icon"
          width="24px"
          aria-hidden="true"
        }}
      </button>
    </aside>
  </template>
}
