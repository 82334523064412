
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


i("./-embroider-implicit-modules.js");

d("market-view-ui/adapters/application", function(){ return i("market-view-ui/adapters/application.js");});
d("market-view-ui/adapters/nces-school", function(){ return i("market-view-ui/adapters/nces-school.js");});
d("market-view-ui/adapters/report", function(){ return i("market-view-ui/adapters/report.js");});
d("market-view-ui/app", function(){ return i("market-view-ui/app.js");});
d("market-view-ui/authenticators/nais", function(){ return i("market-view-ui/authenticators/nais.js");});
d("market-view-ui/breakpoints", function(){ return i("market-view-ui/breakpoints.js");});
d("market-view-ui/config/environment", function(){ return i("market-view-ui/config/environment.js");});
d("market-view-ui/constants", function(){ return i("market-view-ui/constants.js");});
d("market-view-ui/deprecation-workflow", function(){ return i("market-view-ui/deprecation-workflow.js");});
d("market-view-ui/metrics-adapters/custom-google-analytics-four", function(){ return i("market-view-ui/metrics-adapters/custom-google-analytics-four.js");});
d("market-view-ui/metrics-adapters/custom-intercom", function(){ return i("market-view-ui/metrics-adapters/custom-intercom.js");});
d("market-view-ui/metrics-adapters/local-adapter", function(){ return i("market-view-ui/metrics-adapters/local-adapter.js");});
d("market-view-ui/models/address", function(){ return i("market-view-ui/models/address.js");});
d("market-view-ui/models/admissions-list-item", function(){ return i("market-view-ui/models/admissions-list-item.js");});
d("market-view-ui/models/admissions-list", function(){ return i("market-view-ui/models/admissions-list.js");});
d("market-view-ui/models/author", function(){ return i("market-view-ui/models/author.js");});
d("market-view-ui/models/collection", function(){ return i("market-view-ui/models/collection.js");});
d("market-view-ui/models/community-report", function(){ return i("market-view-ui/models/community-report.js");});
d("market-view-ui/models/dataitem", function(){ return i("market-view-ui/models/dataitem.js");});
d("market-view-ui/models/dataset-item", function(){ return i("market-view-ui/models/dataset-item.js");});
d("market-view-ui/models/dataset", function(){ return i("market-view-ui/models/dataset.js");});
d("market-view-ui/models/grade-level", function(){ return i("market-view-ui/models/grade-level.js");});
d("market-view-ui/models/import-failure", function(){ return i("market-view-ui/models/import-failure.js");});
d("market-view-ui/models/integration", function(){ return i("market-view-ui/models/integration.js");});
d("market-view-ui/models/nces-school", function(){ return i("market-view-ui/models/nces-school.js");});
d("market-view-ui/models/report", function(){ return i("market-view-ui/models/report.js");});
d("market-view-ui/models/school-preference", function(){ return i("market-view-ui/models/school-preference.js");});
d("market-view-ui/models/school", function(){ return i("market-view-ui/models/school.js");});
d("market-view-ui/models/user-import", function(){ return i("market-view-ui/models/user-import.js");});
d("market-view-ui/models/user-preference", function(){ return i("market-view-ui/models/user-preference.js");});
d("market-view-ui/models/user", function(){ return i("market-view-ui/models/user.js");});
d("market-view-ui/router", function(){ return i("market-view-ui/router.js");});
d("market-view-ui/sentry", function(){ return i("market-view-ui/sentry.js");});
d("market-view-ui/serializers/application", function(){ return i("market-view-ui/serializers/application.js");});
d("market-view-ui/services/comparisons", function(){ return i("market-view-ui/services/comparisons.js");});
d("market-view-ui/services/data", function(){ return i("market-view-ui/services/data.js");});
d("market-view-ui/services/downloads", function(){ return i("market-view-ui/services/downloads.js");});
d("market-view-ui/services/integrations", function(){ return i("market-view-ui/services/integrations.js");});
d("market-view-ui/services/local-storage", function(){ return i("market-view-ui/services/local-storage.js");});
d("market-view-ui/services/location", function(){ return i("market-view-ui/services/location.js");});
d("market-view-ui/services/log", function(){ return i("market-view-ui/services/log.js");});
d("market-view-ui/services/map-layers", function(){ return i("market-view-ui/services/map-layers.js");});
d("market-view-ui/services/network", function(){ return i("market-view-ui/services/network.js");});
d("market-view-ui/services/notifications", function(){ return i("market-view-ui/services/notifications.js");});
d("market-view-ui/services/onboarding", function(){ return i("market-view-ui/services/onboarding.js");});
d("market-view-ui/services/report-actions", function(){ return i("market-view-ui/services/report-actions.js");});
d("market-view-ui/services/report", function(){ return i("market-view-ui/services/report.js");});
d("market-view-ui/services/sentry", function(){ return i("market-view-ui/services/sentry.js");});
d("market-view-ui/services/session-notification", function(){ return i("market-view-ui/services/session-notification.js");});
d("market-view-ui/services/session", function(){ return i("market-view-ui/services/session.js");});
d("market-view-ui/services/trends", function(){ return i("market-view-ui/services/trends.js");});
d("market-view-ui/services/uploads", function(){ return i("market-view-ui/services/uploads.js");});
d("market-view-ui/session-stores/application", function(){ return i("market-view-ui/session-stores/application.js");});
d("market-view-ui/transforms/array", function(){ return i("market-view-ui/transforms/array.js");});
d("market-view-ui/transforms/object", function(){ return i("market-view-ui/transforms/object.js");});
d("market-view-ui/utils/api-url", function(){ return i("market-view-ui/utils/api-url.js");});
d("market-view-ui/utils/base64-to-blob", function(){ return i("market-view-ui/utils/base64-to-blob.js");});
d("market-view-ui/utils/card-id", function(){ return i("market-view-ui/utils/card-id.js");});
d("market-view-ui/utils/compact-formatters", function(){ return i("market-view-ui/utils/compact-formatters.js");});
d("market-view-ui/utils/compute-financial-gap", function(){ return i("market-view-ui/utils/compute-financial-gap.js");});
d("market-view-ui/utils/contribution-at-income", function(){ return i("market-view-ui/utils/contribution-at-income.js");});
d("market-view-ui/utils/cycle", function(){ return i("market-view-ui/utils/cycle.js");});
d("market-view-ui/utils/flatten", function(){ return i("market-view-ui/utils/flatten.js");});
d("market-view-ui/utils/full-pay-income", function(){ return i("market-view-ui/utils/full-pay-income.js");});
d("market-view-ui/utils/percent-change", function(){ return i("market-view-ui/utils/percent-change.js");});
d("market-view-ui/utils/round-to-nearest-thousand-to-convey-uncertainty-in-calculations", function(){ return i("market-view-ui/utils/round-to-nearest-thousand-to-convey-uncertainty-in-calculations.js");});
d("market-view-ui/utils/transform-keys", function(){ return i("market-view-ui/utils/transform-keys.js");});
d("market-view-ui/services/browser/document", function(){ return i("market-view-ui/services/browser/document.js");});
d("market-view-ui/services/browser/local-storage", function(){ return i("market-view-ui/services/browser/local-storage.js");});
d("market-view-ui/services/browser/navigator", function(){ return i("market-view-ui/services/browser/navigator.js");});
d("market-view-ui/services/browser/session-storage", function(){ return i("market-view-ui/services/browser/session-storage.js");});
d("market-view-ui/services/browser/window", function(){ return i("market-view-ui/services/browser/window.js");});
d("market-view-ui/services/flash-messages", function(){ return i("market-view-ui/services/flash-messages.js");});
d("market-view-ui/services/cookies", function(){ return i("market-view-ui/services/cookies.js");});
d("market-view-ui/data-adapter", function(){ return i("market-view-ui/data-adapter.js");});
d("market-view-ui/initializers/ember-data", function(){ return i("market-view-ui/initializers/ember-data.js");});
d("market-view-ui/services/store", function(){ return i("market-view-ui/services/store.js");});
d("market-view-ui/transforms/date", function(){ return i("market-view-ui/transforms/date.js");});
d("market-view-ui/transforms/number", function(){ return i("market-view-ui/transforms/number.js");});
d("market-view-ui/transforms/string", function(){ return i("market-view-ui/transforms/string.js");});
d("market-view-ui/transforms/boolean", function(){ return i("market-view-ui/transforms/boolean.js");});
d("market-view-ui/services/file-queue", function(){ return i("market-view-ui/services/file-queue.js");});
d("market-view-ui/services/keyboard", function(){ return i("market-view-ui/services/keyboard.js");});
d("market-view-ui/services/media", function(){ return i("market-view-ui/services/media.js");});
d("market-view-ui/services/moment", function(){ return i("market-view-ui/services/moment.js");});
d("market-view-ui/services/page-title", function(){ return i("market-view-ui/services/page-title.js");});
d("market-view-ui/initializers/ember-simple-auth", function(){ return i("market-view-ui/initializers/ember-simple-auth.js");});
d("market-view-ui/instance-initializers/sentry-performance", function(){ return i("market-view-ui/instance-initializers/sentry-performance.js");});
d("market-view-ui/services/-ensure-registered", function(){ return i("market-view-ui/services/-ensure-registered.js");});
d("market-view-ui/initializers/app-version", function(){ return i("market-view-ui/initializers/app-version.js");});
d("market-view-ui/component-managers/glimmer", function(){ return i("market-view-ui/component-managers/glimmer.js");});
d("market-view-ui/services/new-version", function(){ return i("market-view-ui/services/new-version.js");});
d("market-view-ui/services/features", function(){ return i("market-view-ui/services/features.js");});
d("market-view-ui/initializers/viewport-config", function(){ return i("market-view-ui/initializers/viewport-config.js");});
d("market-view-ui/services/in-viewport", function(){ return i("market-view-ui/services/in-viewport.js");});
d("market-view-ui/metrics-adapters/base", function(){ return i("market-view-ui/metrics-adapters/base.js");});
d("market-view-ui/metrics-adapters/google-analytics-four", function(){ return i("market-view-ui/metrics-adapters/google-analytics-four.js");});
d("market-view-ui/metrics-adapters/google-tag-manager", function(){ return i("market-view-ui/metrics-adapters/google-tag-manager.js");});
d("market-view-ui/services/metrics", function(){ return i("market-view-ui/services/metrics.js");});
d("market-view-ui/instance-initializers/ember-router-scroll", function(){ return i("market-view-ui/instance-initializers/ember-router-scroll.js");});
d("market-view-ui/services/router-scroll", function(){ return i("market-view-ui/services/router-scroll.js");});
d("market-view-ui/templates/application", function(){ return i("market-view-ui/templates/application.js");});
d("market-view-ui/controllers/application", function(){ return i("market-view-ui/controllers/application.js");});
d("market-view-ui/routes/application", function(){ return i("market-view-ui/routes/application.js");});
d("market-view-ui/templates/about-our-data", function(){ return i("market-view-ui/templates/about-our-data.js");});
d("market-view-ui/routes/about-our-data", function(){ return i("market-view-ui/routes/about-our-data.js");});
d("market-view-ui/templates/auth", function(){ return i("market-view-ui/templates/auth.js");});
d("market-view-ui/routes/auth", function(){ return i("market-view-ui/routes/auth.js");});
d("market-view-ui/templates/error", function(){ return i("market-view-ui/templates/error.js");});
d("market-view-ui/routes/error", function(){ return i("market-view-ui/routes/error.js");});
d("market-view-ui/templates/index", function(){ return i("market-view-ui/templates/index.js");});
d("market-view-ui/routes/index", function(){ return i("market-view-ui/routes/index.js");});
d("market-view-ui/routes/privacy", function(){ return i("market-view-ui/routes/privacy.js");});
d("market-view-ui/templates/terms", function(){ return i("market-view-ui/templates/terms.js");});
d("market-view-ui/routes/terms", function(){ return i("market-view-ui/routes/terms.js");});
d("market-view-ui/templates/404", function(){ return i("market-view-ui/templates/404.js");});
d("market-view-ui/templates/loading", function(){ return i("market-view-ui/templates/loading.js");});

  if (macroCondition(getGlobalConfig().fastboot?.isRunning)) {
d("market-view-ui/instance-initializers/content-security-policy", function(){ return i("market-view-ui/instance-initializers/content-security-policy.js");});
d("market-view-ui/instance-initializers/setup-fetch", function(){ return i("market-view-ui/instance-initializers/setup-fetch.js");});
  }


w._embroiderRouteBundles_ = [
  {
    names: ["authenticated.map"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.map.js");
    }
  },
  {
    names: ["authenticated.reports.report.details"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.reports.report.details.js");
    }
  },
  {
    names: ["authenticated.reports.report.rename"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.reports.report.rename.js");
    }
  },
  {
    names: ["authenticated.reports.report.share"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.reports.report.share.js");
    }
  },
  {
    names: ["authenticated.reports.report"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.reports.report.js");
    }
  },
  {
    names: ["authenticated.reports"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.reports.js");
    }
  },
  {
    names: ["authenticated.trends.affordability.change-in-average"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.trends.affordability.change-in-average.js");
    }
  },
  {
    names: ["authenticated.trends.affordability.distribution"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.trends.affordability.distribution.js");
    }
  },
  {
    names: ["authenticated.trends.affordability.high-income"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.trends.affordability.high-income.js");
    }
  },
  {
    names: ["authenticated.trends.affordability.index"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.trends.affordability.index.js");
    }
  },
  {
    names: ["authenticated.trends.affordability.median"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.trends.affordability.median.js");
    }
  },
  {
    names: ["authenticated.trends.affordability.race-ethnicity"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.trends.affordability.race-ethnicity.js");
    }
  },
  {
    names: ["authenticated.trends.affordability"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.trends.affordability.js");
    }
  },
  {
    names: ["authenticated.trends.diversity.change"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.trends.diversity.change.js");
    }
  },
  {
    names: ["authenticated.trends.diversity.compare-zips"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.trends.diversity.compare-zips.js");
    }
  },
  {
    names: ["authenticated.trends.diversity.ethnicity"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.trends.diversity.ethnicity.js");
    }
  },
  {
    names: ["authenticated.trends.diversity.index"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.trends.diversity.index.js");
    }
  },
  {
    names: ["authenticated.trends.diversity"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.trends.diversity.js");
    }
  },
  {
    names: ["authenticated.trends.enrollment.index"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.trends.enrollment.index.js");
    }
  },
  {
    names: ["authenticated.trends.enrollment.opportunities"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.trends.enrollment.opportunities.js");
    }
  },
  {
    names: ["authenticated.trends.enrollment.trends.applications"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.trends.enrollment.trends.applications.js");
    }
  },
  {
    names: ["authenticated.trends.enrollment.trends.diversity"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.trends.enrollment.trends.diversity.js");
    }
  },
  {
    names: ["authenticated.trends.enrollment.trends.enrollments"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.trends.enrollment.trends.enrollments.js");
    }
  },
  {
    names: ["authenticated.trends.enrollment.trends.financial-aid"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.trends.enrollment.trends.financial-aid.js");
    }
  },
  {
    names: ["authenticated.trends.enrollment.trends"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.trends.enrollment.trends.js");
    }
  },
  {
    names: ["authenticated.trends.enrollment"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.trends.enrollment.js");
    }
  },
  {
    names: ["authenticated.trends.index.diversity"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.trends.index.diversity.js");
    }
  },
  {
    names: ["authenticated.trends.index.income"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.trends.index.income.js");
    }
  },
  {
    names: ["authenticated.trends.index.population"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.trends.index.population.js");
    }
  },
  {
    names: ["authenticated.trends.index"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.trends.index.js");
    }
  },
  {
    names: ["authenticated.trends.student-body.change"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.trends.student-body.change.js");
    }
  },
  {
    names: ["authenticated.trends.student-body.index"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.trends.student-body.index.js");
    }
  },
  {
    names: ["authenticated.trends.student-body.private-school"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.trends.student-body.private-school.js");
    }
  },
  {
    names: ["authenticated.trends.student-body"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.trends.student-body.js");
    }
  },
  {
    names: ["authenticated.trends"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.trends.js");
    }
  },
  {
    names: ["authenticated.callbacks.blackbaud"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.callbacks.blackbaud.js");
    }
  },
  {
    names: ["authenticated.comparisons.index.financial-aid-applicants"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.comparisons.index.financial-aid-applicants.js");
    }
  },
  {
    names: ["authenticated.comparisons.index.financial-sustainability"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.comparisons.index.financial-sustainability.js");
    }
  },
  {
    names: ["authenticated.comparisons.index.tuition-discounting"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.comparisons.index.tuition-discounting.js");
    }
  },
  {
    names: ["authenticated.comparisons.index"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.comparisons.index.js");
    }
  },
  {
    names: ["authenticated.comparisons.resources.index.index"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.comparisons.resources.index.index.js");
    }
  },
  {
    names: ["authenticated.comparisons.resources.index.page"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.comparisons.resources.index.page.js");
    }
  },
  {
    names: ["authenticated.comparisons.resources.index"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.comparisons.resources.index.js");
    }
  },
  {
    names: ["authenticated.comparisons.resources.terms"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.comparisons.resources.terms.js");
    }
  },
  {
    names: ["authenticated.comparisons.resources"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.comparisons.resources.js");
    }
  },
  {
    names: ["authenticated.comparisons"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.comparisons.js");
    }
  },
  {
    names: ["authenticated.data.admissions-list"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.data.admissions-list.js");
    }
  },
  {
    names: ["authenticated.data.beta"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.data.beta.js");
    }
  },
  {
    names: ["authenticated.data.blackbaud.connect"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.data.blackbaud.connect.js");
    }
  },
  {
    names: ["authenticated.data.blackbaud.index"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.data.blackbaud.index.js");
    }
  },
  {
    names: ["authenticated.data.dasl"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.data.dasl.js");
    }
  },
  {
    names: ["authenticated.data.dataset"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.data.dataset.js");
    }
  },
  {
    names: ["authenticated.data.finalsite.connect"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.data.finalsite.connect.js");
    }
  },
  {
    names: ["authenticated.data.index.index"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.data.index.index.js");
    }
  },
  {
    names: ["authenticated.data.index.miscellaneous"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.data.index.miscellaneous.js");
    }
  },
  {
    names: ["authenticated.data.index"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.data.index.js");
    }
  },
  {
    names: ["authenticated.data.integration"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.data.integration.js");
    }
  },
  {
    names: ["authenticated.data.upload"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.data.upload.js");
    }
  },
  {
    names: ["authenticated.data.veracross.connect"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.data.veracross.connect.js");
    }
  },
  {
    names: ["authenticated.data.veracross.index"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.data.veracross.index.js");
    }
  },
  {
    names: ["authenticated.data.loading"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.data.loading.js");
    }
  },
  {
    names: ["authenticated.data"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.data.js");
    }
  },
  {
    names: ["authenticated.zips.zip"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.zips.zip.js");
    }
  },
  {
    names: ["authenticated"],
    load: function() {
      return import("market-view-ui/assets/_route_/authenticated.js");
    }
  },
]


if (!runningTests) {
  i("../app").default.create({"name":"market-view-ui","version":"0.0.0+74dfce1"});
}

