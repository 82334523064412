import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';

import config from 'market-view-ui/config/environment';

export default class LoginButton extends Component {
  urlBase = `${config.nais.ssoURL}/sso/login`;
  appCode = 'MarketView';

  @service session;
  @service localStorage;
  @service metrics;
  @service router;
  @service('browser/window') window;

  get ssoUrl() {
    let returnUrl = window.location.origin;

    return `${this.urlBase}?appCode=${this.appCode}&returnUrl=${returnUrl}/auth`;
  }

  @action
  @waitFor
  async goToLogin() {
    if (await this.session.authenticateWithSSOCookie()) {
      this.ifLoggingInFromLandingRedirect();
      return;
    }

    this.localStorage.save(
      'attemptedUrl',
      this.session?.attemptedTransition?.intent?.url ?? 'authenticated.trends',
    );
    this.trackLogin(this.ssoUrl);
    let wind = this.window.open(this.ssoUrl, '_self');
    wind.opener = null;
  }

  trackLogin(url) {
    this.metrics.trackEvent({
      category: 'User',
      action: 'User SSO',
      label: url,
      transport: 'beacon',
    });
  }

  ifLoggingInFromLandingRedirect() {
    if (this.router.currentRouteName === 'index') {
      this.router.transitionTo('authenticated.trends');
    }
  }
  <template>
    <button {{on "click" this.goToLogin}} class="btn--primary" ...attributes>
      Log in with NAIS
    </button>
  </template>
}
