import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class Dataset extends Model {
  @attr('string')
  name;

  @attr('string')
  mimeType;

  @attr('object')
  directUploadCredentials;

  @attr('boolean', { defaultValue: true })
  uploadProcessed;

  @attr('date')
  insertedAt;

  @belongsTo('collection', { async: true, inverse: null })
  collection;

  @hasMany('dataitems', { async: true, inverse: 'datasets' })
  dataitems;

  @belongsTo('user-import', { async: false, inverse: null })
  userImport;

  get validItems() {
    return this.dataitems.filter((item) => item.valid);
  }

  get uploadedAt() {
    return this.insertedAt;
  }

  get isUploadInProgress() {
    return this.userImport?.isUploadInProgress;
  }

  get hasRecentFailedUpload() {
    let userImport = this.userImport;
    return (
      userImport?.isUploadComplete &&
      userImport?.hasFailures &&
      userImport?.isRecent
    );
  }

  get hasFailedUpload() {
    let userImport = this.userImport;
    return userImport?.isUploadComplete && userImport?.hasFailures;
  }
}
