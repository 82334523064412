import { service } from '@ember/service';
import Model, { attr, belongsTo } from '@ember-data/model';
import moment from 'moment';

import { DEFAULT_AGE_RANGES } from '../constants';
import transformKeys from '../utils/transform-keys';

export default class Report extends Model {
  @attr('string', { defaultValue: 'Untitled Report' })
  title;

  @attr('string')
  driveTime;

  @attr('string')
  sex;

  @attr('string')
  tuition;

  @attr('object')
  rawData;

  @attr('array')
  agePrefs; // Supported only on Library for saved reports

  @attr('array', {
    defaultValue() {
      return DEFAULT_AGE_RANGES;
    },
  })
  ageRanges;

  @attr('boolean')
  favorite;

  @attr('date')
  insertedAt;

  @attr('number')
  lat;

  @attr('number')
  long;

  @attr('string')
  address;

  @belongsTo('collection', { inverse: 'reports', async: true })
  collection;

  @belongsTo('author', { async: false, inverse: null })
  author;

  get hasData() {
    return !!this.geoData;
  }

  get geoData() {
    if (!this.rawData) {
      return null;
    }
    return {
      // eslint-disable-next-line camelcase
      drive_time: this.rawData.drive_time,
      // eslint-disable-next-line camelcase
      zip_codes: this.rawData.zip_codes.map((zip) => {
        return Object.assign(
          {
            id: zip.id,
            geometry: zip.geometry,
          },
          transformKeys(zip?.attributes),
        );
      }),
    };
  }

  get prettyReportDate() {
    return moment(this.insertedAt).format('MMM DD, YYYY');
  }

  get params() {
    let report = this;
    return {
      lat: report.lat,
      long: report.long,
      address: report.address,
      age_ranges: report.ageRanges, // eslint-disable-line camelcase
      tuition: report.tuition,
      sex: report.sex,
      drive_time: report.driveTime, // eslint-disable-line camelcase
    };
  }

  get coordinates() {
    return [this.long, this.lat];
  }

  get ageRangesConsideringAgePrefs() {
    if (this.agePrefs?.length) {
      return ageRangesFrom(this.agePrefs);
    }
    return this.ageRanges;
  }

  @service
  network;

  async downloadPdf() {
    return await this.network.request('/api/reports/create_pdf', {
      method: 'POST',
      data: {
        report_params: this.params, // eslint-disable-line camelcase
      },
    });
  }

  async share(data) {
    return await this.network.request(`/api/reports/${this.id}/share`, {
      method: 'POST',
      data,
    });
  }
}

export function ageRangesFrom(preferences) {
  let selectedAges = preferences.filter((age) => age.selected);
  return [...new Set(selectedAges.map((item) => item.range))];
}
