import { ContributionRates } from './contribution-at-income';

class ContributionRateNotFoundError extends Error {}
export function fullPayIncome(tuition) {
  if (!tuition) {
    return 0;
  }

  let fullContributionRateGroup = ContributionRates.find((rate) => {
    return rate.rate * rate.high >= tuition;
  });
  if (!fullContributionRateGroup) {
    throw new ContributionRateNotFoundError(
      `No contribution rate found for tuition amount ${tuition}`,
    );
  }
  return Math.round(
    Math.max(
      tuition / fullContributionRateGroup.rate,
      fullContributionRateGroup.low,
    ),
  );
}
