import Service, { service } from '@ember/service';
import { capitalize } from '@ember/string';
import { tracked } from '@glimmer/tracking';
import {
  task,
  restartableTask,
  keepLatestTask,
  dropTask,
  timeout,
  all,
} from 'ember-concurrency';

import { errorMessages, successMessages } from '../constants';
import { ERROR_TYPES, IMPORT_STATUSES } from '../models/user-import';

const POLL_INTERVAL = 1000;
export default class IntegrationsService extends Service {
  @service store;

  @service data;

  @service log;

  @service notifications;

  @service metrics;

  @service router;

  @tracked
  syncs = [];

  loadInProgress = dropTask(async () => {
    let syncs = await this.store.query('user-import', {
      filter: {
        kind: 'integration',
        status: IMPORT_STATUSES.inProgress,
      },
    });
    this.addInProgressSyncs(syncs.slice());
  });

  addInProgressSyncs(syncs) {
    this.syncs = [...this.syncs, ...syncs];
    this.poll.perform();
  }

  refreshAll = dropTask(async () => {
    this.metrics.trackEvent({
      category: 'Your Data',
      action: 'Refresh All Integrations',
    });
    for (let integration of this.integrations) {
      await this.refreshIntegrationAndPollForStatus.perform(integration);
    }
  });

  refreshIntegration = dropTask(async (integration) => {
    if (!integration) {
      throw new Error('No DASL integration found');
    }
    this.metrics.trackEvent({
      category: 'Your Data',
      action: 'Refresh Integration',
      label: integration.kind,
    });
    await this.refreshIntegrationAndPollForStatus.perform(integration);
  });

  refreshIntegrationAndPollForStatus = task(
    { maxConcurrency: 3 },
    async (integration) => {
      let userImport = this.store.createRecord('user-import', {
        kind: 'integration',
        source: integration.kind,
        status: IMPORT_STATUSES.pending,
      });

      try {
        await userImport.save();
        await integration.reload();
        this.syncs = [...this.syncs, userImport];
        this.poll.perform();
      } catch (e) {
        this.log.error(e);
        this.notifications.error(
          errorMessages.refreshIntegration(userImport.sourceName),
        );
      }
    },
  );

  poll = restartableTask(async () => {
    while (this.syncs.length > 0) {
      try {
        for (let sync of this.syncs) {
          await sync.reload();
          if (sync.isComplete) {
            this.syncs = this.syncs.filter((s) => s.id !== sync.id);
            this.syncComplete.perform();
            if (sync.isSuccessful) {
              this.notifications.success(
                successMessages.refreshIntegration(sync.sourceName),
              );
            } else {
              this.notifications.error(errorMessageForSync(sync));
            }
          }
        }
        await timeout(POLL_INTERVAL);
      } catch (e) {
        this.log.error(e);
      }
    }
  });

  syncComplete = keepLatestTask(async () => {
    if (this.router.currentRouteName.startsWith('authenticated.data')) {
      await this.router.refresh(this.router.currentRouteName);
    }
  });

  deleteIntegrationData = dropTask(async (imports) => {
    let source = imports[0].source;
    this.metrics.trackEvent({
      category: 'Your Data',
      action: 'Delete Integration Data',
      label: source,
    });
    try {
      let deletes = imports.map((userImport) => userImport.destroyRecord());
      await all(deletes);
      this.notifications.success(
        successMessages.deleteIntegrationData(capitalize(source)),
      );
    } catch (e) {
      this.notifications.error(
        errorMessages.deleteIntegrationData(capitalize(source)),
      );
      this.log.error(e);
    }
  });

  disconnectIntegration = dropTask(async (integration) => {
    let kind = integration.kind;
    this.metrics.trackEvent({
      category: 'Your Data',
      action: 'Disconnect Integration',
      label: kind,
    });
    try {
      await integration.destroyRecord();
      if (this.router.currentRouteName.startsWith('authenticated.data')) {
        await this.router.refresh(this.router.currentRouteName);
      }
      this.notifications.success(
        successMessages.disconnectIntegration(capitalize(kind)),
      );
    } catch (e) {
      this.notifications.error(
        errorMessages.disconnectIntegration(capitalize(kind)),
      );
      this.log.error(e);
    }
  });

  isPollingForSource(source) {
    return (
      this.poll.isRunning && this.syncs.some((sync) => sync.source === source)
    );
  }

  get currentlyPollingSourceNames() {
    return [...new Set(this.syncs?.map((sync) => sync.sourceName))];
  }

  get isPolling() {
    return this.poll.isRunning;
  }

  get integrations() {
    return this.data.load.lastSuccessful?.value?.integrations?.slice();
  }

  get userImports() {
    return (
      this.data.load.lastSuccessful?.value &&
      this.store.peekAll('user-import')?.slice()
    );
  }

  get veracrossImports() {
    return this.userImports.filter((userImport) => userImport.isVeracross);
  }

  get blackbaudImports() {
    return this.userImports.filter((userImport) => userImport.isBlackbaud);
  }

  get dasl() {
    return this.integrations?.find((integration) => integration.isDasl);
  }

  get veracross() {
    return this.integrations?.find((integration) => integration.isVeracross);
  }

  get blackbaud() {
    return this.integrations?.find((integration) => integration.isBlackbaud);
  }

  get hasEverConnectedToVeracrossAndFetchedData() {
    return this.veracross || this.veracrossImports.length > 0;
  }

  get hasEverConnectedToBlackbaudAndFetchedData() {
    return this.blackbaud || this.blackbaudImports.length > 0;
  }
}

function errorMessageForSync(userImport) {
  switch (userImport.errorType) {
    case ERROR_TYPES.badClientCredentials:
      return errorMessages.refreshIntegrationBadCredentials(
        userImport.sourceName,
      );
    case ERROR_TYPES.missingApiScope:
      return errorMessages.refreshIntegrationMissingScopes(
        userImport.sourceName,
      );
    default:
      return errorMessages.refreshIntegration(userImport.sourceName);
  }
}
