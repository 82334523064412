import Model, { attr, belongsTo } from '@ember-data/model';

export default class ImportFailureModel extends Model {
  @attr('string')
  source;

  @attr('number')
  line;

  @attr('string')
  reason;

  @belongsTo('user-import', { async: true, inverse: 'failures' })
  userImport;
}
