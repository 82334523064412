import { hash } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import featureFlag from 'ember-feature-flags/helpers/feature-flag';
import svgJar from 'ember-svg-jar/helpers/svg-jar';

import NavBar from 'market-view-ui/components/nav-bar';
import trackEvent from 'market-view-ui/helpers/track-event';
import clickOutsideIfActive from 'market-view-ui/modifiers/click-outside-if-active';

export default class HeaderBar extends Component {
  @service location;
  @service metrics;

  @tracked showNav = false;

  @action
  toggleNav() {
    this.showNav = !this.showNav;

    this.metrics.trackEvent({
      category: 'UI',
      action: `Side Nav ${openOrClosed(this.showNav)}`,
    });
  }

  @action
  hideNav() {
    this.showNav = false;
    this.metrics.trackEvent({
      category: 'UI',
      action: `Side Nav Closed`,
    });
  }
  <template>
    <header class="l-header">
      <div class="l-header-wrap">
        <div
          class="nav__icon-wrap"
          {{clickOutsideIfActive this.hideNav isActive=this.showNav}}
        >
          <button
            {{on "click" this.toggleNav}}
            aria-label="Toggle Navigation Menu"
            class="nav__icon-menu icon-menu
              {{if this.showNav 'nav__icon-menu--is-active'}}"
            data-test-header-bar-nav-menu-toggle
          >
            <div class="icon-menu__bar icon-menu__top-bar"></div>
            <div class="icon-menu__bar icon-menu__center-bar"></div>
            <div class="icon-menu__bar icon-menu__bottom-bar"></div>
          </button>

          <NavBar
            data-test-header-bar-nav-bar
            @showNav={{this.showNav}}
            @toggleNav={{this.toggleNav}}
          />
        </div>

        <nav class="header__nav-links">
          <LinkTo
            @route="authenticated.trends"
            @activeClass="header__nav-link--is-active"
            class="header__nav-link"
            data-test-header-bar-link-trends
          >
            Trends
          </LinkTo>

          <LinkTo
            @route="authenticated.map"
            @query={{hash
              address=this.location.address
              lat=this.location.lat
              long=this.location.long
            }}
            @activeClass="header__nav-link--is-active"
            class="header__nav-link test-header-tabs-build-your-view"
            data-test-header-map-link
          >
            Maps
          </LinkTo>

          {{#if (featureFlag "comparisons")}}
            <LinkTo
              @route="authenticated.comparisons"
              @activeClass="header__nav-link--is-active"
              class="header__nav-link"
              data-test-header-bar-link-comparisons
            >
              Comparisons
            </LinkTo>
          {{/if}}

          <LinkTo
            @route="authenticated.data"
            @activeClass="header__nav-link--is-active"
            class="header__nav-link"
            data-test-header-bar-link-data
          >
            Your Data
          </LinkTo>
        </nav>

        <div class="header__logo-wrap">
          <a href="/" aria-label="National Association of Independent Schools">
            <picture class="header__logo">
              <source
                media="(max-width: 799px)"
                width="103"
                height="10"
                srcset="/assets/svgs/mobile-only-logo.svg"
              />
              <source
                srcset="/assets/svgs/mv-logo.svg"
                width="245"
                height="51"
              />
              <img
                class="header__logo"
                srcset="/assets/images/mobile-only-logo.png 103w, /assets/svgs/mv-logo.svg 246w"
                sizes="(max-width: 799px) 103px, (min-width: 800px) 246px"
                src="/assets/svgs/mv-logo.svg"
                alt="Market View"
              />
            </picture>
          </a>
        </div>

        <div class="header__help">
          <a href="#" class="js-intercom-launcher">Chat</a>
          <a
            href="https://docs.marketview.nais.org/nais"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Help"
            {{on
              "click"
              (trackEvent "User" "Help Viewed" "from header" isOutbound=true)
            }}
          >
            {{svgJar "question-circle" width="16px" aria-hidden="true"}}
          </a>
        </div>
      </div>
    </header>
  </template>
}

function openOrClosed(isOpen) {
  if (isOpen) {
    return 'Opened';
  } else {
    return 'Closed';
  }
}
