import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import config from 'market-view-ui/config/environment';

export default class LogoutButton extends Component {
  @service metrics;
  @service session;
  @service('browser/window') window;

  urlBase = `${config.nais.ssoURL}/sso/logout`;
  appCode = 'MarketView';

  get logoutUrl() {
    let returnUrl = window.location.origin;

    return `${this.urlBase}?appCode=${this.appCode}&returnUrl=${returnUrl}`;
  }

  @action
  async logout() {
    this.metrics.trackEvent({
      category: 'User',
      action: 'User Log out',
      transport: 'beacon',
    });
    await this.session.invalidate();
    let wind = this.window.open(this.logoutUrl, '_self');
    wind.opener = null;
  }
  <template>
    <button {{on "click" this.logout}} ...attributes data-test-logout>
      {{yield}}
    </button>
  </template>
}
