import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { restartableTask, timeout } from 'ember-concurrency';

import config from 'market-view-ui/config/environment';

import { successMessages } from '../constants';

const POLL_TIMEOUT = config.nais.aiGenerationPollTimeout;
const MAX_RETRIES = 5760;
export default class ComparisonsService extends Service {
  @service network;
  @service notifications;
  @service session;
  @service log;
  @service router;

  @tracked isAIAnalysisGenerating = false;

  get isAIAnalysisEnabled() {
    return this.session.currentUser?.school?.schoolPreference?.aiOptIn ?? false;
  }

  toggleAIAnalysis = restartableTask(async (newValue) => {
    const preference = this.session.currentUser?.school?.schoolPreference;
    const oldVal = preference.aiOptIn;
    preference.aiOptIn = newValue;
    try {
      await preference.save();
      if (newValue) {
        this.pollForGenerationJobStatus.perform(); // Intentionally NOT awaited
      } else {
        this.isAIAnalysisGenerating = false;
        this.pollForGenerationJobStatus.cancelAll();
        await this.router.refresh('authenticated.comparisons');
      }
    } catch (e) {
      preference.aiOptIn = oldVal;
      throw e;
    }
  });

  pollForGenerationJobStatus = restartableTask(async () => {
    let retries = 0;
    while (retries < MAX_RETRIES) {
      try {
        let result = await this.network.request(
          '/api/jobs?filter[state]=executing,available,scheduled,retryable&filter[kind]=comparisons-update&sort=-scheduled-at&page[size]=1',
        );
        if (result.data.length > 0) {
          this.isAIAnalysisGenerating = true;
          await timeout(POLL_TIMEOUT);
        } else {
          if (this.isAIAnalysisGenerating) {
            this.isAIAnalysisGenerating = false;
            await this.refreshComparisonsDataIfOnComparisonsRouteOrNotifyUser.perform();
          }
          break;
        }
      } catch (e) {
        this.log.error(e);
        break;
      }
      retries++;
    }
    if (retries >= MAX_RETRIES) {
      this.log.error('pollForGenerationJobStatus hit max retries');
    }
  });

  refreshComparisonsDataIfOnComparisonsRouteOrNotifyUser = restartableTask(
    async () => {
      if (
        this.router.currentRouteName.startsWith('authenticated.comparisons')
      ) {
        await this.router.refresh('authenticated.comparisons').then(() => {
          this.notifications.success(
            successMessages.aiSummariesGeneratedOnComparisons,
          );
        });
      } else {
        this.notifications.success(successMessages.aiSummariesGenerated);
      }
    },
  );
}
