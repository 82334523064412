import Service from '@ember/service';

export default class DownloadsService extends Service {
  downloadBlob(data, type, filename) {
    const blob = new Blob([data], { type });

    const objectUrl = window.URL.createObjectURL(blob);
    let link = document.createElement('a');

    link.href = objectUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(objectUrl);
  }
}
