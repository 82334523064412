import Model, { attr } from '@ember-data/model';

import { IMPORT_STATUSES } from './user-import';

export default class IntegrationModel extends Model {
  @attr('string')
  kind;

  @attr('string')
  clientId;

  @attr('string')
  clientSecret;

  @attr('string')
  baseUrl;

  @attr('string')
  lastImportStatus;

  @attr('date')
  lastImportAt;

  @attr('string')
  code; // transitiory field for exchanging code for access token

  get isDasl() {
    return this.kind === 'dasl';
  }

  get isVeracross() {
    return this.kind === 'veracross';
  }

  get isBlackbaud() {
    return this.kind === 'blackbaud';
  }

  get lastImportIsSuccessful() {
    return this.lastImportStatus === IMPORT_STATUSES.done;
  }

  get lastImportIsError() {
    return this.lastImportStatus === IMPORT_STATUSES.error;
  }

  get lastImportInProgress() {
    return (
      this.lastImportStatus === IMPORT_STATUSES.inProgress ||
      this.lastImportStatus === IMPORT_STATUSES.pending
    );
  }
}
