import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { timeout, dropTask } from 'ember-concurrency';

import config from 'market-view-ui/config/environment';

export default class SessionNotificationService extends Service {
  @service session;
  @tracked currentTime = new Date().getTime();

  isEnabled = config.authentication.pollForSessionNotifications;
  // eslint-disable-next-line no-magic-numbers
  updateFrequency = 60000;
  // eslint-disable-next-line no-magic-numbers
  expirationBuffer = 300000;

  constructor() {
    super(...arguments);
    if (this.isEnabled) {
      this.updateCurrentTime.perform();
    }
  }

  updateCurrentTime = dropTask(async () => {
    while (true) {
      this.currentTime = new Date().getTime();
      await timeout(this.updateFrequency);
    }
  });

  get validUntil() {
    return this.session.validUntil;
  }

  get isSessionValid() {
    if (this.validUntil) {
      return this.currentTime < this.validUntil;
    }
    return true;
  }

  get isSessionInvalid() {
    return !this.isSessionValid;
  }

  get isSessionExpiring() {
    if (this.validUntil) {
      return (
        this.isSessionValid &&
        this.currentTime + this.expirationBuffer > this.validUntil
      );
    }
    return false;
  }
}
