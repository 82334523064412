/* eslint-disable no-magic-numbers */

const CURRENT_DATA_YEAR = 2023;
const FORECAST_DATA_YEAR = 2028;
const PAST_DATA_YEAR = 2013;

const MEDIAN_NATIONAL_TUITION = 27950;

const URLS = {
  'http://localhost:4200': 'http://localhost:4000',
  'http://localhost.test:4200': 'http://localhost.test:4000',
  'https://insights.nais.org': 'https://api.marketview.nais.org',
  'https://marketview.nais.org': 'https://api.marketview.nais.org',
  default: 'https://market-view-api-staging.onrender.com',
};

const tuitionOptions = [
  '1000',
  '2000',
  '3000',
  '4000',
  '5000',
  '6000',
  '7000',
  '8000',
  '9000',
  '10000',
  '11000',
  '12000',
  '13000',
  '14000',
  '15000',
  '16000',
  '17000',
  '18000',
  '19000',
  '20000',
  '21000',
  '22000',
  '23000',
  '24000',
  '25000',
  '26000',
  '27000',
  '28000',
  '29000',
  '30000',
  '31000',
  '32000',
  '33000',
  '34000',
  '35000',
  '36000',
  '37000',
  '38000',
  '39000',
  '40000',
  '41000',
  '42000',
  '43000',
  '44000',
  '45000',
  '46000',
  '47000',
  '48000',
  '49000',
  '50000',
  '51000',
  '52000',
  '53000',
  '54000',
  '55000',
  '56000',
  '57000',
  '58000',
  '59000',
  '60000',
  '61000',
  '62000',
  '63000',
  '64000',
  '65000',
  '66000',
  '67000',
  '68000',
  '69000',
  '70000',
  '71000',
  '72000',
  '73000',
  '74000',
  '75000',
  '76000',
  '77000',
  '78000',
  '79000',
  '80000',
];

const driveTimeOptions = ['5', '10', '15', '20', '30', '40', '50', '60'];

const errorMessages = {
  ageRangePrefs: {
    headline: 'Unable to save age range preferences',
    message:
      'We are unable to save your age range preferences at this time.  Try again or email marketview@nais.org for help.',
  },
  deleteReport: {
    headline: 'Unable to delete report',
    message:
      'We are unable to delete your report at this time.  Try again or email marketview@nais.org for help.',
  },
  downloadReport: {
    headline: 'Unable to download report',
    message:
      'We are unable to download your report at this time.  Try again or email marketview@nais.org for help.',
  },
  favorites: {
    headline: 'Unable to add report to favorites',
    message:
      'We are unable to add this report to favorites at this time.  Try again or email marketview@nais.org for help.',
  },
  zipCodeAddFavorites: {
    headline: 'Unable to add ZIP code to favorites',
    message:
      'We are unable to add this ZIP code to favorites at this time.  Try again or email marketview@nais.org for help.',
  },
  zipCodeRemoveFavorites: {
    headline: 'Unable to remove ZIP code from favorites',
    message:
      'We are unable to remove this ZIP code from favorites at this time.  Try again or email marketview@nais.org for help.',
  },
  zipCodeAddHidden: {
    headline: 'Unable to hide ZIP code',
    message:
      'We are unable to hide this ZIP code at this time.  Try again or email marketview@nais.org for help.',
  },
  zipCodeRemoveHidden: {
    headline: 'Unable to remove ZIP code from hidden',
    message:
      'We are unable to remove this ZIP code from hidden at this time.  Try again or email marketview@nais.org for help.',
  },
  notLoggedIn: {
    headline: 'You can’t log in',
    message:
      'Hmm, looks like something isn’t right here. You are unable to login. To gain access, reach out to membership@nais.org.',
    timeout: 10000,
  },
  onboarding: {
    headline: 'Unable to update your user’s status',
    message:
      'We are unable to update your user’s status at this time.  Try again or email marketview@nais.org for help.',
  },
  outsideUS: {
    headline:
      'Hello! It looks like you’re accessing this page from a school outside of the United States.',
    message:
      'At this time, Market View is only available for schools inside the U.S.',
    timeout: 10000,
  },
  renameReport: {
    headline: 'Unable to rename report',
    message:
      'We are unable to rename your report at this time.  Try again or email marketview@nais.org for help.',
  },
  reportNotSaved: {
    headline: 'This report couldn’t be saved',
    message: 'Oh no! This report couldn’t be saved. Please try again!',
  },
  reportNotGenerated: {
    headline: 'This report couldn’t be generated',
    message:
      'Oh no! This report couldn’t be generated. Please Try again or email marketview@nais.org for help!',
  },
  forecastNotAvailable: {
    headline: 'This forecast could not be fetched',
    message:
      'Oh no! This forecast could not be fetched. Please Try again or email marketview@nais.org for help!',
  },
  reportView: {
    headline: 'Report view does not exist',
    message: 'The report view you are trying to access does not exist.',
  },
  shareReport: {
    headline: 'Unable to share your report',
    message:
      'We are unable to share your report at this time.  Try again or email marketview@nais.org for help.',
  },
  unauthorized: {
    headline: 'Hey! Where’s your hall pass?',
    message:
      'It looks like you aren’t authorized to use Market View because your account is not affiliated with an NAIS member school. Please reach out to membership@nais.org.',
    timeout: 10000,
  },
  unknown: {
    headline: 'Something went wrong',
    message:
      'Whoops, that wasn’t supposed to happen. Please check your work and try again!',
    timeout: 10000,
  },
  deleteFile: {
    headline: 'This file could not be deleted',
    message:
      'We are unable to delete your file at this time.  Try again or contact our help desk.',
  },
  renameFile: {
    headline: 'This file could not be renamed',
    message:
      'We are unable to rename your file at this time.  Try again or contact our help desk.',
  },
  addUpload: {
    headline: 'This file could not be processed',
    message:
      'We were unable to add this file. Please try again or contact our help desk.',
  },
  addUploadBadFileFormat: {
    headline: 'This file was unable to be processed',
    message: 'Please check that the format of the file matches the template.',
  },
  addUploadBadFileType: {
    headline: 'This file was unable to be processed',
    message: 'Please ensure the file is formatted as a .csv file.',
    timeout: 20000,
  },
  downloadFailedRows: {
    headline: 'The failed rows were unable to be downloaded',
    message:
      'We were unable to download the failed rows at this time. Please try again or contact our help desk.',
  },
  deleteFailedRows: {
    headline: 'The failed rows were unable to be deleted',
    message:
      'We were unable to delete the failed rows at this time. Please try again or contact our help desk.',
  },
  aiSummaryToggle: {
    headline: 'Unable to toggle summaries',
    message:
      'We were unable to toggle the AI summaries at this time. Please try again or contact our help desk.',
  },
  refreshIntegration(source) {
    return {
      headline: `Unable to fetch data from ${source}`,
      message: `We were unable to refresh your ${source} data at this time. Please try again or contact our help desk.`,
    };
  },
  refreshIntegrationBadCredentials(source) {
    return {
      headline: `Unable to fetch data from ${source}`,
      message: `We were unable to connect with ${source}. Please update your credentials and try again or contact our help desk.`,
    };
  },
  refreshIntegrationMissingScopes(source) {
    return {
      headline: `Unable to fetch data from ${source}`,
      message: `Please ensure the correct scopes are set in the integration with ${source} and then try again or contact our help desk.`,
    };
  },
  connectToIntegration(source) {
    return {
      headline: `Unable to connect to ${source}`,
      message: `We were unable to connect to ${source}. Please try again or contact our help desk.`,
    };
  },
  deleteIntegrationData(source) {
    return {
      headline: `Unable to delete ${source} data`,
      message: `We were unable to delete your ${source} data at this time. Please try again or contact our help desk.`,
    };
  },
  disconnectIntegration(source) {
    return {
      headline: `Unable to disconnect from ${source}`,
      message: `We were unable to disconnect from ${source}. Please try again or contact our help desk.`,
    };
  },
  401: {
    headline: 'Hey! Where’s your hall pass?',
    message:
      'Your session is invalid/expired and you have been logged out. Please log in again.',
    timeout: 10000,
  },
};

const successMessages = {
  addFavorite: {
    headline: 'Report favorited',
    message: 'Your report has been added to favorites.',
    icon: 'star-filled',
  },
  zipCodeAddFavorite: {
    headline: 'ZIP code favorited',
    message: 'The ZIP code has been added to favorites.',
    icon: 'star-filled',
  },
  zipCodeAddHidden: {
    headline: 'ZIP code hidden',
    message: 'The ZIP code has been hidden.',
    icon: 'star-filled',
  },
  deleteReport: {
    headline: 'Report deleted',
    message: 'Your report has been deleted.',
    icon: 'trash',
  },
  downloadReport: {
    headline: 'Report downloaded',
    message: 'Your report has been downloaded.',
    icon: 'download',
  },
  removeFavorite: {
    headline: 'Report favorite removed',
    message: 'Your report has been removed from favorites.',
    icon: 'star-empty',
  },
  zipCodeRemoveFavorite: {
    headline: 'ZIP code removed from favorites',
    message: 'The ZIP code has been removed from favorites.',
    icon: 'star-empty',
  },
  zipCodeRemoveHidden: {
    headline: 'ZIP code removed from hidden',
    message: 'The ZIP code has been removed from hidden.',
    icon: 'star-empty',
  },
  renameReport: {
    headline: 'Report title updated',
    message: 'Your report title has been updated.',
    icon: 'edit',
  },
  reportRegenerated: {
    headline: 'Map has been regenerated',
    message: 'Your map has been regenerated from a saved report.',
    icon: 'map',
  },
  saveReport: {
    headline: 'Report created',
    message: 'Your report has been created.',
    icon: 'file',
  },
  shareReport: {
    headline: 'Report shared',
    message: 'Your report has been sent to the provided email addresses.',
    icon: 'people',
  },
  deleteFile: {
    headline: 'File deleted',
    message: 'Your file was deleted',
    icon: 'trash',
  },
  renameFile: {
    headline: 'File rename',
    message: 'Your file was renamed',
    icon: 'file',
  },
  addUpload: {
    headline: 'Addresses processed',
    message: 'Your addresses have been processed.',
    icon: 'file',
  },
  downloadFailedRows: {
    headline: 'Failed rows downloaded',
    message: 'Your failed rows have been downloaded as a .csv file.',
    icon: 'file',
  },
  deleteFailedRows: {
    headline: 'Failed rows deleted',
    message: 'Your failed rows have been deleted.',
    icon: 'trash',
  },
  refreshIntegration(source) {
    return {
      headline: `Fetched latest data from ${source}`,
      message: `The latest data from ${source} has been imported.`,
      icon: 'check',
    };
  },
  connectToIntegration(source) {
    return {
      headline: `Connected to ${source}`,
      message: `The latest data from ${source} is now being fetched.`,
      icon: 'check',
    };
  },
  deleteIntegrationData(source) {
    return {
      headline: `Deleted data from ${source}`,
      message: `All imported data from ${source} has been deleted.`,
      icon: 'trash',
    };
  },
  disconnectIntegration(source) {
    return {
      headline: `Disconnected from ${source}`,
      message: `The connection to ${source} has been disconnected. Until you reconnect, you will no longer be able to sync data from ${source}.`,
      icon: 'check',
    };
  },
  aiSummariesToggledOn: {
    headline: 'Your summaries are on their way.',
    message: 'This can take a few minutes. Please be patient.',
    icon: 'heroicons-sparkles',
  },
  aiSummariesToggledOff: {
    headline: 'Okay! Your summaries have been deleted.',
    message: '',
    icon: 'heroicons-sparkles',
  },
  aiSummariesGenerated: {
    headline: 'Your AI summaries are ready!',
    message: 'Head to Comparisons to see them.',
    icon: 'heroicons-sparkles',
  },
  aiSummariesGeneratedOnComparisons: {
    headline: 'Your AI summaries are ready!',
    message: `We've refreshed the data on this page for you.`,
    icon: 'heroicons-sparkles',
  },
};

const admissionsTemplate =
  'First name,Last name,Address,ID,Grade\nJane,Doe,"2134 KALORAMA RD NW, WASHINGTON	DC	20008",1ac,1\nJoan,Smith,"3501 INTERNATIONAL PL NW, WASHINGTON	DC	20008",2de,2\nRob,Roberts,"3523 INTERNATIONAL CT NW, WASHINGTON	DC	20008",3ef,3\nSam,Smith,"2410 CALIFORNIA ST NW, WASHINGTON	DC	20008",4gh,4\nJohn,Doe,"2375 PENNSYLVANIA AV NW, WASHINGTON	DC	20037",5ij,5\n';

const MAX_INCOME = 99999999;

const DEFAULT_AGE_RANGES = [[0, 17]];

const HIGHCHARTS_IDENTIFIER_FIELD = '__mv-identifier';

export {
  driveTimeOptions,
  errorMessages,
  successMessages,
  tuitionOptions,
  URLS,
  admissionsTemplate,
  FORECAST_DATA_YEAR,
  CURRENT_DATA_YEAR,
  PAST_DATA_YEAR,
  MAX_INCOME,
  MEDIAN_NATIONAL_TUITION,
  DEFAULT_AGE_RANGES,
  HIGHCHARTS_IDENTIFIER_FIELD,
};
